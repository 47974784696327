
// 组件
import TopStoryShow from './components/TopStoryShow.vue';
import CategoryTab from '@/pages/front-desk/components/CategoryTab.vue'; // 类型列表
import VideoBox from './components/VideoBox'; // 视频盒子
import Footer from '@/pages/front-desk/components/footer/Footer.vue'; // 页脚
// api
import { getActicleList } from '@/api/client-api/front_desk';
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  name: 'StoreStory',
  components: {
    TopStoryShow,
    CategoryTab,
    VideoBox,
    Footer,
  },
  data() {
    return {
      breadList: [{ name: '商家故事', link: '' }], // 面包屑路径
      activeCate: '', // 当前选中的分类id
      activeCateName: '', // 选中的文章类型名字
      acticleList: [], // 商家故事列表
      // 分页相关
      total: 19,
      pageNum: 1,
      pageSize: 6,
    };
  },
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    let videoTopList = []; // 置顶的商家故事
    let cateList = []; // 类别列表
    let acticleList = []; // 文章列表
    let total = 0; // 文章分页
    let pageNum = 1;
    let pageSize = 6;

    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    try {
      const [resCarouseList, resCateList, resActicleList, resTdk] =
        await Promise.all([
          // 置顶的商家故事
          app.$getArticleList({ classify: 'store_story', top_status: '1' }),
          // 类别列表
          app.$getNewsCateList({ classify: 'store_story' }),
          // 文章列表
          app.$getArticleList({
            classify: 'store_story',
            pageNum,
            pageSize,
          }),
          // 获取TDK
          app.$getSeoInfo({ id: '34' }),
        ]);
      if (resCarouseList?.code == 1) {
        videoTopList = resCarouseList?.data?.list || [];
      }
      if (resCateList?.code == 1) {
        cateList = resCateList?.data || [];
      }
      if (resActicleList?.code == 1) {
        acticleList = resActicleList?.data?.list || [];
        total = Number(resActicleList?.data?.total) || 0;
      }
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return {
        videoTopList,
        cateList,
        acticleList,
        total,
        pageNum,
        pageSize,
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log('front-desk/store-story', error?.status, error?.statusText);
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        videoTopList,
        cateList,
        acticleList,
        total,
        pageNum,
        pageSize,
        Title,
        Keywords,
        Description,
      };
    }
  },
  methods: {
    // 文章类型改变
    changeCategory(id, name) {
      this.activeCate = id;
      this.activeCateName = name;
      this.pageNum = 1;
      // 重新获取文章列表
      this.getActicleList();
    },
    // 获取文章列表
    async getActicleList() {
      try {
        let options = {
          classify: 'store_story',
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        if (this.activeCate) options.type = this.activeCateName;
        const res = await getActicleList(options);
        if (res?.code == 1) {
          this.acticleList = res?.data?.list || [];
          this.total = Number(res?.data?.total) || 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 跳转分页
    goPages(n) {
      this.pageNum = Number(n);
      this.getActicleList(); // 重新获取文章列表
    },
  },
};
