// 根据不同的apiType请求不同的接口配置
// 需要另外的接口自己拓展
export const apiType = {
  1: 'operationLogRecordBanner', //banner相关
  2: 'operationLogRecordCoreAdvantages', //核心优势
  3: 'operationLogRecordConfig', //系统设置相关
  4: 'operationLogRecordHotSearch', //热搜词
  5: 'operationLogRecordNews', //新闻相关
  6: 'operationLogRecordCate', //规则分类
  7: 'operationLogRecordOpinion', //意见征集内容相关
  8: 'operationLogCateList', // 品类相关
};
export const apiSearchType = {
  1: 'modifyingFieldsBanner', //banner相关
  2: 'modifyingFieldsCoreAdvantages', //核心优势
  3: 'modifyingFieldsConfig', //系统设置相关
  4: 'modifyingFieldsHotSearch', //热搜词
  5: 'modifyingFieldsNews', //新闻相关
  6: 'modifyingFields', //规则分类
  7: 'modifyingFieldsOpinion', //意见征集内容
};
// 根据不同的searchlisttype来决定用多少个搜索
// 后续有拓展自己加 注意：别打错字
export const searchList = {
  1: ['修改方式', '展示顺序', '修改字段', '操作人', '操作时间'],
  2: ['展示顺序', '修改字段', '操作人', '操作时间'],
  3: ['修改方式', '操作人', '操作时间'],
  4: ['修改字段', '操作人', '操作时间'],
  5: ['修改方式', '修改字段', '操作人', '操作时间'],
};
