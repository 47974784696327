export function animation(time, from, to, onProgress) {
  if (process.client) {
    // 初始值 -> 最终值的差值
    const dis = to - from;
    // 每次动多少
    const speed = dis / time;
    // 开始时间
    const startTime = Date.now();
    // 初始化
    let value = from;
    onProgress(value);
    function _run() {
      // 当前时间
      const now = Date.now();
      // 每次当前时间与开始时间的时间差
      const timeDifference = now - startTime;
      // 当时间差大于等于指定时间
      if (timeDifference >= time) {
        value = to;
        onProgress(value);
        return;
      }
      // 时间差 * 每次动多少
      const d = timeDifference * speed;
      value = from + d;
      onProgress(value);
      window.requestAnimationFrame(_run);
    }

    window.requestAnimationFrame(_run);
  }
}
