
import ImageShow from '@/pages/front-desk/components/ImageShow';
import NeedMaterial from './NeedMaterial';
export default {
  name: 'SupplierActicle',
  components: { ImageShow, NeedMaterial },
  props: {
    // 指导视频路径
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pointList: [
        { name: '入驻准备', id: 'ready' },
        { name: '注册指导', id: 'guide' },
        { name: '后续工作', id: 'follow' },
      ],
      activityPoint: 'ready',
    };
  },
  computed: {},
  mounted() {
    let that = this;
    window.onscroll = function () {
      //scrollTop就是触发滚轮事件时滚轮的高度
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log('滚动距离' + scrollTop, that.activityPoint);
      // const list = document.querySelectorAll("a");
      if (scrollTop < 1000) {
        that.activityPoint = 'ready';
      } else if (scrollTop < 1700) {
        that.activityPoint = 'guide';
      } else {
        that.activityPoint = 'follow';
      }
    };
  },
  methods: {
    // 点击锚点变化样式
    clickAnchorPointer(id) {
      this.activityPoint = id;
    },
  },
};
