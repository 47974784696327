
// 板块组成变量
import { seo } from './js/seo';
// 组件
import TdkTable from './components/TdkTable';
import OperationLog from './components/operation-Log/OperationLog';

export default {
  name: 'LearningCenter',
  components: { TdkTable, OperationLog },
  data() {
    return {
      mainList: seo,
    };
  },
  mounted() {},
  methods: {},
};
