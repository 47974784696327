export default ({ $axios }, inject) => {
  // banner图
  inject('getBanner', (data) =>
    $axios({
      url: '/cn/banner',
      method: 'get',
      params: data,
    })
  );
  // 核心优势
  inject('getCoreAdvantages', (data) =>
    $axios({
      url: '/cn/coreAdvantages',
      method: 'get',
      params: data,
    })
  );
  // 最新动态
  inject('getHmoeNews', (data) =>
    $axios({
      url: '/cn/getNews',
      method: 'get',
      params: data,
    })
  );
  // 文章及视频列表接口
  inject('getArticleList', (data) =>
    $axios({
      url: '/cn/getArticleList',
      method: 'get',
      params: data,
    })
  );
  // 获取文章详情
  inject('getDetail', (data) =>
    $axios({
      url: '/cn/getDetail',
      method: 'get',
      params: data,
    })
  );
  // 意见征集列表
  inject('opinionList', (data) =>
    $axios({
      url: '/cn/opinionList',
      method: 'get',
      params: data,
    })
  );
  // 意见征集详情
  inject('getOpinionDetail', (data) =>
    $axios({
      url: '/cn/getOpinionDetail',
      method: 'get',
      params: data,
    })
  );
  // 细目大家庭
  inject('getFamily', (data) =>
    $axios({
      url: '/cn/getFamily',
      method: 'get',
      params: data,
    })
  );
  // 获取发展历程
  inject('getDevelopmentHistory', (data) =>
    $axios({
      url: '/cn/getDevelopmentHistory',
      method: 'get',
      params: data,
    })
  );
  // 供货商注册指导、零售商运营学习视频
  inject('getChannel', (data) =>
    $axios({
      url: '/cn/channel',
      method: 'get',
      params: data,
    })
  );
  // 获取新闻类别列表
  inject('getNewsCateList', (data) =>
    $axios({
      url: '/cn/getClassifyType',
      method: 'get',
      params: data,
    })
  );

  // 获取页面TDK
  inject('getSeoInfo', (data) =>
    $axios({
      url: '/cn/getSeoInfo',
      method: 'get',
      params: data,
    })
  );
  // 搜索招聘信息、获取招聘详情
  inject('getJobMessage', (data) => $axios({
    url: '/cn/Recruitment/search',
    method: 'get',
    params: data
  }))
};
