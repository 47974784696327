
// api
import { changepassword,logoff } from '@/api/client-api/backstageapi.js';
export default {
  name: 'Modification',
  data() {
    return {
      modification: {
        newPassword: '',
        samePassword: '',
      },
    };
  },
  watch: {},
  methods: {
    // 修改密码
    changePass() {
      const options = {
        password: this.modification.newPassword,
        password_again: this.modification.samePassword,
      };
      if (this.modification.newPassword !== this.modification.samePassword) {
        this.$message.warning('密码不一致');
        return;
      }
      changepassword(options).then((res) => {
        if (res.code === 1) {
          this.$message.success('修改成功');
          this.exit();
        }
      });
    },
    // 退出登录
    exit() {
      // window.sessionStorage.clear();//销毁token
      logoff().then((res) => {
        if (res.code === 1) {
          this.$router.push('/Login');
        }
      });
    },
  },
};
