export const tableConfig = [
  {
    text: '手机号',
    filed: 'phone',
    width: '150px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '分类',
    filed: 'type',
    width: '62px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '公司名称',
    filed: 'company',
    width: '80px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '建议内容',
    filed: 'content',
    width: '160px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '提交时间',
    filed: 'create_time',
    width: '160px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '备注',
    filed: 'remark',
    width: '120px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '181px',
    isSort: false,
    isEdit: true,
  },
];
