// 意见征集内容设置
export const setSolicitationViewsField = [
  {
    text: '文章标题',
    filed: 'title',
    width: '160px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '封面图',
    filed: 'images',
    width: '130px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '征集期范围',
    filed: 'timeframe',
    width: '104px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '发布时间',
    filed: 'publish_time',
    width: '170px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '修改时间',
    filed: 'update_time',
    width: '170px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '操作人',
    filed: 'operator',
    width: '100px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '116px',
    isSort: false,
    isEdit: true,
  },
];
