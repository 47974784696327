
// 组件
import CoreStrengths from './components/CoreStrengths'; //核心优势
import StartSelling from './components/StartSelling'; //立即开店
import GlobalDistribution from './components/GlobalDistribution'; //全球布局
import LatestDevelopments from './components/LatestDevelopments'; //最新动态
import Footer from '@/pages/front-desk/components/footer/Footer.vue'; //底部
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  components: {
    CoreStrengths,
    StartSelling,
    GlobalDistribution,
    LatestDevelopments,
    Footer,
  },
  data() {
    return {};
  },
  // 需要seo的
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    let bannerList = []; //banner图列表
    let coreAdvantagesList = []; //核心优势数据列表
    let hmoeNewsList = []; //最新动态列表

    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';

    // 最新动态列表处理
    function setList(list) {
      const arr = [];
      // 将列表分成 parseInt(i/4) + 1 份
      for (let i = 0; i < list.length; i += 4) {
        arr.push(list.slice(i, i + 4));
      }
      return arr;
    }
    try {
      const [resBanner, resCoreAdvantages, resHmoeNews, resTdk] =
        await Promise.all([
          // 首页banner图
          app.$getBanner({ classify: 'home_page' }),
          // 核心优势
          app.$getCoreAdvantages(),
          // 最新动态
          app.$getHmoeNews(),
          // 获取TDK
          app.$getSeoInfo({ id: '25' }),
        ]);
      // 首页banner图
      if (resBanner?.code == 1) {
        bannerList = resBanner?.data || [];
      }
      // 核心优势
      if (resCoreAdvantages?.code == 1) {
        coreAdvantagesList = resCoreAdvantages?.data || [];
      }
      // 最新动态
      if (resHmoeNews?.code == 1) {
        const list = JSON.parse(JSON.stringify(resHmoeNews?.data || [])) || [];
        hmoeNewsList = setList(list);
      }
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return {
        bannerList,
        coreAdvantagesList,
        hmoeNewsList,
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/home-page/index.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        bannerList,
        coreAdvantagesList,
        hmoeNewsList,
        Title,
        Keywords,
        Description,
      };
    }
  },
};
