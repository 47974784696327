
export default {
  props: {
    table: {
      type: Array,
      default: () => [],
    }, //表格数据
    tableFiled: {
      type: Array,
      default: () => [],
    }, //列配置
    isBottomBorder: {
      //是否要下边框
      type: Boolean,
      default: false,
    },
    isLevelScrollBar: {
      //是否有横线滚动条
      type: Boolean,
      default: false,
    },
    tableHeight: {
      //限制表格最大高度  溢出显示滚动条（表头固定）
      type: [Number, String],
      default: 'auto',
    },
    // 某些时候，表格的字段配置不会实时刷新
    // 因为 el-table-column 上设置的 key 值不唯一
    // 这个字段就是用于防止 key 值不唯一
    tableKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    //排序触发回调
    sortChangeFunc({ column, prop, order }) {
      this.$emit('getSortDataEvent', prop, order);
    },
    // table头部样式
    headerStyleFunc() {
      return 'xm-table-header-bg';
    },
  },
  mounted() {},
};
