
// 组件
import Footer from '@/pages/front-desk/components/footer/Footer'; //底部
import CommentsDetails from '../components/CommentsDetails'; //新闻详情
import SubmitProposals from '../components/SubmitProposals';
export default {
  components: { Footer, CommentsDetails, SubmitProposals },
  data() {
    return {
      breadList: [
        { name: '经营支持', link: '/jyzc' },
        { name: '意见征集', link: '/zj' },
        { name: '文章详情', link: '' },
      ],
    };
  },
  // 需要seo
  async asyncData(app) {
    let detailsInfo = {}; //新闻详情数据
    let params = app?.route?.params || {};
    let articleList = []; //新闻列表
    try {
      const detailsOptions = {};
      if (params?.id) {
        detailsOptions.id = params?.id;
      }
      const [resNewsDetails] = await Promise.all([
        // 新闻详情
        app.$getOpinionDetail(detailsOptions),
      ]);
      // 新闻详情
      if (resNewsDetails?.code == 1) {
        detailsInfo = resNewsDetails?.msg || {};
      }
      return {
        detailsInfo,
        articleList,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/home-page/index.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        detailsInfo,
        articleList,
      };
    }
  },
};
