export const logconfig = [
  {
    anchorpoint: 'rulestype',
    title: '规则分类',
    cate_menu: 'rulestype',
    apiType: '6',
    searchlisttype: '1',
    tableType: '1',
  },
  {
    anchorpoint: 'rulestext',
    title: '规则内容',
    cate_menu: 'cate',
    apiType: '5',
    searchlisttype: '1',
    tableType: '1',
  },
];
