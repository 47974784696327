
// 组件
import CategoryTab from '@/pages/front-desk/components/CategoryTab.vue'; // 类型列表
import SingleBanner from '@/pages/front-desk/components/SingleBanner'; //单张banner组件
import VideoBox from './components/VideoBox.vue'; // 视频盒子
import Footer from '@/pages/front-desk/components/footer/Footer.vue'; // 页脚
// api
import { getActicleList } from '@/api/client-api/front_desk';
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  name: 'OperateVideo',
  components: {
    CategoryTab,
    VideoBox,
    Footer,
    SingleBanner,
  },
  data() {
    return {
      activeCate: '', // 当前选中的分类id
      activeCateName: '', // 选中的文章类型名字
      videoList: [], // 操作视频列表
      // 面包屑路径
      breadList: [{ name: '操作视频', link: '' }],
      // 分页相关
      // 区分分页类型
      pageToolsType: '2', //是否为手机 1-手机 2-pc,平板类型
      total: 0,
      pageNum: 1,
      pageSize: 9,
    };
  },
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    let cover = {}; // 封面图数据
    let cateList = []; // 类别列表
    let videoList = []; // 操作视频列表
    let total = 0; // 文章分页
    let pageNum = 1;
    let pageSize = 9;

    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    try {
      const [resCarouseList, resCateList, resActicleList, resTdk] =
        await Promise.all([
          // 轮播图列表
          app.$getBanner({ classify: 'learning_video' }),
          // 类别列表
          app.$getNewsCateList({ classify: 'learning_video' }),
          // 文章列表
          app.$getArticleList({
            classify: 'learning_video',
            pageNum,
            pageSize,
          }),
          // 获取TDK
          app.$getSeoInfo({ id: '33' }),
        ]);
      if (resCarouseList?.code == 1) {
        cover = resCarouseList?.data?.[0] || {};
      }
      if (resCateList?.code == 1) {
        cateList = resCateList?.data || [];
      }
      if (resActicleList?.code == 1) {
        videoList = resActicleList?.data?.list || [];
        total = Number(resActicleList?.data?.total) || 0;
      }
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return {
        cover,
        cateList,
        videoList,
        total,
        pageNum,
        pageSize,
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/learn-center/video',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        cover,
        cateList,
        videoList,
        total,
        pageNum,
        pageSize,
        Title,
        Keywords,
        Description,
      };
    }
  },
  methods: {
    // 文章类型改变
    changeCategory(id, name) {
      this.videoList = [];
      this.activeCate = id;
      this.activeCateName = name;
      this.pageNum = 1;
      // 重新获取文章列表
      this.getActicleList();
    },
    // 获取文章列表
    async getActicleList() {
      try {
        let options = {
          classify: 'learning_video',
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        if (this.activeCate) options.type = this.activeCateName;
        const res = await getActicleList(options);
        if (res?.code == 1) {
          this.videoList = res?.data?.list || [];
          this.total = Number(res?.data?.total) || 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 跳转分页
    goPages(n) {
      this.pageNum = Number(n);
      this.getActicleList(); // 重新获取文章列表
    },
    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 769) {
        this.pageToolsType = '1';
      } else {
        this.pageToolsType = '2';
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener(
        'resize',
        this._throttle(this.checkScreenWidth, 150)
      );
    });
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.checkScreenWidth);
    }
  },
};
