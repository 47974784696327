// 这里不仅用于导航的锚点
// 还用于操作日志标签切换的数据
// cate_menu 是用于区分点击标签得到哪个配置（传给后端的,部分后端定的）

// apiType 是为了让前端区分用哪个请求的(取决于后端接口)
// 1 - banner 的日志 2 - 核心优势设置 的日志 3 - 系统配置的操作日志（立即开店视频设置/侧边栏设置/底部二维码设置）
// 4 - 热搜词设置的日志
// 例子：pages\back-stage\components\operation-Log\js\configinfo.js

// searchlisttype 区分用那几个搜索，例子：pages\back-stage\components\operation-Log\js\configinfo.js

// tableType 区分用哪一种表格配置 例子:pages\back-stage\components\operation-Log\js\tableConfig.js
export const homeBackstage = [
  {
    anchorpoint: 'sethomebanner',
    hasbtn: true,
    title: '首页banner设置',
    cate_menu: 'home_page',
    apiType: '1',
    searchlisttype: '1',
    tableType: '1',
  },
  {
    anchorpoint: 'setcorestrengths',
    title: '核心优势设置',
    cate_menu: 'corestrengths',
    apiType: '2',
    searchlisttype: '2',
    tableType: '2',
  },
  {
    anchorpoint: 'setopenshop',
    title: '立即开店视频设置',
    cate_menu: 'shopVideo',
    apiType: '3',
    searchlisttype: '3',
    tableType: '3',
  },
  {
    anchorpoint: 'setside',
    title: '侧边栏设置',
    cate_menu: 'sidebar',
    apiType: '3',
    searchlisttype: '4',
    tableType: '4',
  },
  {
    anchorpoint: 'setqrcode',
    title: '底部二维码设置',
    cate_menu: 'bottomQr',
    apiType: '3',
    searchlisttype: '3',
    tableType: '5',
  },
  {
    anchorpoint: 'sethotkey',
    hasbtn: true,
    title: '设置热搜词',
    cate_menu: 'hotkey',
    apiType: '4',
    searchlisttype: '1',
    tableType: '1',
  },
  {
    anchorpoint: 'operationlog',
    title: '操作日志',
  },
];
