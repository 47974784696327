
import MobileNavigation from './components/MobileNavigation.vue';
export default {
  components: { MobileNavigation },
  name: 'TopNavigation',
  props: {
    // 传进需要高亮的值
    needHighLight: {
      type: String,
      default: '',
    },
    // 一级标签对应id
    navId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      navList: [
        { id: 1, name: '首页', link: '/', list: [] },
        {
          id: 2,
          name: '入驻通道',
          link: '',
          list: [
            { name: '供货商入驻', link: '/ghs' },
            { name: '零售商入驻', link: '/lss' },
          ],
        },
        {
          id: 3,
          name: '行业动态',
          link: '',
          list: [
            // { name: '政策动态', link: '/zc' },
            { name: '国内新闻', link: '/gn' },
            { name: '跨境资讯', link: '/kj' },
            { name: '公司新闻', link: '/news' },
          ],
        },
        {
          id: 4,
          name: '学习中心',
          link: '',
          list: [
            { name: '选品推荐', link: '/xp' },
            { name: '运营干货', link: '/yy' },
            { name: '操作视频', link: '/sp' },
          ],
        },
        // {
        // id:5,
        //   name: '商家故事',
        //   link: '/storestory',
        //   list: [],
        // },
        {
          id: 6,
          name: '经营支持',
          link: '/jyzc',
          list: [],
        },
        {
          id: 7,
          name: '关于我们',
          link: '',
          list: [
            { name: '企业简介', link: '/xm' },
            { name: '加入我们', link: '/zp' },
          ],
        },
      ],
      currentPath: '', // 当前所在页面的路由
      isPc: false,
    };
  },

  computed: {
    belongTo() {
      return this.findNameByLink(this.currentPath, this.navList);
    },
  },
  methods: {
    // 判断当前页面属于
    judgePageBelongto() {
      for (let i = 0; i < this.navList.length; i++) {
        const item = this.navList[i];
        if (item.link === this.currentPath) {
          console.log(item.name);
          return item.name;
        }
        if (item.list && item.list.length > 0) {
          const subItem = judgePageBelongto(this.currentPath, item.list);
          if (subItem) {
            console.log(subItem);
            return subItem;
          }
        }
      }
      return null;
    },
    findNameByLink(link, navList) {
      for (let i = 0; i < navList.length; i++) {
        const item = navList[i];
        if (item.link === link) {
          return item.name;
        }
        if (item.list && item.list.length > 0) {
          const subItem = this.findNameByLink(link, item.list);
          if (subItem) return item.name;
        }
      }
      return null;
    },

    // ---------------------------------------
    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        this.isPc = false;
      } else {
        this.isPc = true;
      }
    },
  },
  mounted() {
    this.currentPath = '/' + this.$route.path.split('/')?.[1];

    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener(
        'resize',
        this._throttle(this.checkScreenWidth, 150)
      );
    });
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.checkScreenWidth);
    }
  },
};
