import { render, staticRenderFns } from "./NoticeChange.vue?vue&type=template&id=8597c8d6&scoped=true"
import script from "./NoticeChange.vue?vue&type=script&lang=js"
export * from "./NoticeChange.vue?vue&type=script&lang=js"
import style0 from "./NoticeChange.vue?vue&type=style&index=0&id=8597c8d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8597c8d6",
  null
  
)

export default component.exports