
export default {
  data() {
    return {
      noticeList: [],
    };
  },
  mounted() {
    this.getNoticeList();
  },
  methods: {
    /**
     * 页面触发
     */
    // 获取公告列表
    getNoticeList() {
      let list = [
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
        { title: '测试', time: '2023-03-02' },
      ];
      for (let i = 0; i < list.length; i + 6) {
        this.noticeList.push(list.splice(i, i + 6));
      }
      console.log(this.noticeList, 'this.noticeList');
    },
  },
};
