
// api
import { addOpinion } from '@/api/client-api/front_desk.js';
export default {
  data() {
    return {
      dataInfo: {
        type: '1', //1供货商 2零售商
        name: '', //输入的姓名
        company: '', //输入的公司名称
        phone: '', //手机号
        content: '', //建议内容
      }, //需要提交给后端的数据
      checked: false, //是否勾选
    };
  },
  methods: {
    /**
     * 页面触发
     */
    // 切换用户类型
    changeUser(userType) {
      this.reData();
      this.dataInfo.type = userType;
    },
    // “点击提交”
    handleAddOpinion() {
      // 零售商的公司名称不是必填的
      for (let i in this.dataInfo) {
        if (this.dataInfo['type'] == '1') {
          if (!this.dataInfo[i]) {
            this.$message.warning('有必填项没填');
            return;
          }
        } else if (this.dataInfo['type'] == '2') {
          if (!this.dataInfo[i] && i != 'company') {
            this.$message.warning('有必填项没填');
            return;
          }
        }
      }
      if (!this.checked) {
        this.$message.warning(
          '请您同意ximu.cn根据《隐私政策》收集及使用您的上述信息'
        );
        return;
      }
      // 通过校验提交
      this.submitInfo();
    },
    // 提交数据
    async submitInfo() {
      let res = await addOpinion(this.dataInfo);
      if (res?.code == 1) {
        this.$message.success(res.msg);
      }
    },
    // 重置数据
    reData() {
      this.dataInfo = {
        name: '', //输入的姓名
        company: '', //输入的公司名称
        phone: '', //手机号
        content: '', //建议内容
      }; //需要提交给后端的数据
      this.checked = false; //是否勾选
    },
  },
};
