import request from '@/utils/request.js';
// -----------------------------------------------------
// 获取设置数据
// 侧边栏二维码,侧边栏手机号,底部二维码,店铺视频
export function getListData(data) {
  return request({
    url: '/xmadmin/system.config/sitList',
    method: 'get',
    params: data,
    // controller: {
    //   openLoading:true
    // }
  });
}
// 设置开店视频
export function editShopVideo(data) {
  return request({
    url: '/xmadmin/system.config/shopVideo',
    method: 'post',
    data: data,
  });
}
// 设置侧边栏图片
export function editSidebar(data) {
  return request({
    url: '/xmadmin/system.config/sidebar',
    method: 'post',
    data: data,
  });
}
// 设置底部二维码
export function editBottomQr(data) {
  return request({
    url: '/xmadmin/system.config/bottomQr',
    method: 'post',
    data: data,
  });
}

//登录
export function login(data) {
  return request({
    url: '/xmadmin/login/index',
    method: 'post',
    data: data,
  });
}
// 退出登录）
export function logoff(data) {
  return request({
    url: '/xmadmin/login/out',
    method: 'get',
    params: data,
  });
}

//修改密码
export function changepassword(data) {
  return request({
    url: '/xmadmin/system.admin/password',
    method: 'post',
    data: data,
  });
}

// ---------------------------------------------------------------------------

// 获取banner图设置列表数据（首页）
export function getBannerList(data) {
  return request({
    url: '/xmadmin/site.banner/index',
    method: 'get',
    params: data,
  });
}

// 删除banner图
export function deleteBanner(data) {
  return request({
    url: '/xmadmin/site.banner/delete',
    method: 'post',
    data: data,
  });
}

// 添加banner图（首页）
export function addBanner(data) {
  return request({
    url: '/xmadmin/site.banner/add',
    method: 'post',
    data: data,
  });
}
// 编辑banner图（首页）
export function editBanner(data) {
  return request({
    url: '/xmadmin/site.banner/edit',
    method: 'post',
    data: data,
  });
}

// ---------------------------------------------------------------------
// 核心优势

// 获取核心优势列表
export function getAdvantageList(data) {
  return request({
    url: '/xmadmin/site.CoreAdvantages/index',
    method: 'get',
    params: data,
  });
}

// 编辑核心优势
export function editorAdvantage(data) {
  return request({
    url: '/xmadmin/site.CoreAdvantages/edit',
    method: 'post',
    data: data,
  });
}

// --------------------------------------------------------------------------------
// 热搜词

// 获取热搜词列表
export function getHotSearch(data) {
  return request({
    url: '/xmadmin/site.HotSearch/index',
    method: 'get',
    params: data,
  });
}

// 删除热搜词
export function deketeHotSearch(data) {
  return request({
    url: '/xmadmin/site.HotSearch/delete',
    method: 'get',
    params: data,
  });
}

// 新增热搜词
export function saveHotSearch(data) {
  return request({
    url: '/xmadmin/site.HotSearch/add',
    method: 'post',
    data: data,
  });
}

// 编辑热搜词
export function editHotSearch(data) {
  return request({
    url: '/xmadmin/site.HotSearch/edit',
    method: 'post',
    data: data,
  });
}

// ---------------------------------------------------------------------

// 获取新闻列表（国内新闻）
export function getNews(data) {
  return request({
    url: '/xmadmin/news.news/index',
    method: 'get',
    params: data,
  });
}

// 删除新闻列表
// 对应官网系统类别 home_page：首页 ，industry_dynamic：行业动态，policy_dynamics: 政策动态，dynamic_domestic：国内新闻 ，dynamic_border：跨境资讯，dynamic_company：公司新闻，learning_center：学习中心，learning_recommend：选品推荐，learning_operating：运营干货，learning_video：操作视频
export function deleteNews(data) {
  return request({
    url: '/xmadmin/news.news/delete',
    method: 'post',
    data: data,
  });
}

// 编辑新闻列表
// 对应官网系统类别 home_page：首页 ，industry_dynamic：行业动态，policy_dynamics: 政策动态，dynamic_domestic：国内新闻 ，dynamic_border：跨境资讯，dynamic_company：公司新闻，learning_center：学习中心，learning_recommend：选品推荐，learning_operating：运营干货，learning_video：操作视频
export function editNews(data) {
  return request({
    url: '/xmadmin/news.news/edit',
    method: 'post',
    data: data,
  });
}

// --------------------------------------------------------------------------
// 专门放操作日志相关的
// banner的操作日志
export function operationLogRecordBanner(data) {
  return request({
    url: '/xmadmin/site.banner/operationLogRecord',
    method: 'get',
    params: data,
  });
}
// banner修改字段
export function modifyingFieldsBanner(data) {
  return request({
    url: '/xmadmin/site.banner/modifyingFields',
    method: 'get',
    params: data,
  });
}

// 系统设置的操作日志
export function operationLogRecordConfig(data) {
  return request({
    url: '/xmadmin/system.config/operationLogRecord',
    method: 'get',
    params: data,
  });
}
// 系统设置修改字段
export function modifyingFieldsConfig(data) {
  return request({
    url: '/xmadmin/system.config/modifyingFields',
    method: 'get',
    params: data,
  });
}

// 热搜词的操作日志
export function operationLogRecordHotSearch(data) {
  return request({
    url: '/xmadmin/site.HotSearch/operationLogRecord',
    method: 'get',
    params: data,
  });
}
// 热搜词修改字段
export function modifyingFieldsHotSearch(data) {
  return request({
    url: '/xmadmin/site.HotSearch/modifyingFields',
    method: 'get',
    params: data,
  });
}

// 核心优势的操作日志
export function operationLogRecordCoreAdvantages(data) {
  return request({
    url: '/xmadmin/site.CoreAdvantages/operationLogRecord',
    method: 'get',
    params: data,
  });
}
// 核心优势修改字段
export function modifyingFieldsCoreAdvantages(data) {
  return request({
    url: '/xmadmin/site.CoreAdvantages/modifyingFields',
    method: 'get',
    params: data,
  });
}

// 新闻内容相关
export function operationLogRecordNews(data) {
  return request({
    url: '/xmadmin/news.news/operationLogRecord',
    method: 'get',
    params: data,
  });
}
// 新闻内容相关修改字段
export function modifyingFieldsNews(data) {
  return request({
    url: '/xmadmin/news.news/modifyingFields',
    method: 'get',
    params: data,
  });
}
// 意见征集内容设置相关
export function operationLogRecordOpinion(data) {
  return request({
    url: '/xmadmin/news.Opinion/operationLogRecord',
    method: 'get',
    params: data,
  });
}
// 意见征集内容设置修改字段相关
export function modifyingFieldsOpinion(data) {
  return request({
    url: '/xmadmin/news.Opinion/modifyingFields',
    method: 'get',
    params: data,
  });
}

// 规则分类
export function operationLogRecordCate(data) {
  return request({
    url: '/xmadmin/news.cate/operationLogRecord',
    method: 'get',
    params: data,
  });
}
// 规则分类搜索
export function modifyingFields(data) {
  return request({
    url: '/xmadmin/news.cate/modifyingFields',
    method: 'get',
    params: data,
  });
}
// -----------------------------------------------------

// 用户列表
export function getUser(data) {
  return request({
    url: '/xmadmin/system.admin/index',
    method: 'get',
    params: data,
  });
}

// -----------------------------------------
// 意见征集内容列表
export function getOpinionList(data) {
  return request({
    url: '/xmadmin/news.Opinion/getOpinionList',
    method: 'get',
    params: data,
  });
}
// 删除意见征集内容
export function deleteOpinion(data) {
  return request({
    url: '/xmadmin/news.Opinion/deleteOpinion',
    method: 'post',
    data: data,
  });
}
// 新增意见征集内容
export function addOpinion(data) {
  return request({
    url: '/xmadmin/news.Opinion/addOpinion',
    method: 'post',
    data: data,
  });
}
// 编辑意见征集内容
export function editOpinion(data) {
  return request({
    url: '/xmadmin/news.Opinion/editOpinion',
    method: 'post',
    data: data,
  });
}

// --------------------------------------------------------

// 意见收集数据
export function getOpinionRecord(data) {
  return request({
    url: '/xmadmin/news.Opinion/getOpinionRecord',
    method: 'get',
    params: data,
  });
}

// 删除用户意见
export function deleteOpinionRecord(data) {
  return request({
    url: '/xmadmin/news.Opinion/deleteOpinionRecord',
    method: 'post',
    data: data,
  });
}

// 编辑用户意见备注
export function editOpinionRecord(data) {
  return request({
    url: '/xmadmin/news.Opinion/editOpinionRecord',
    method: 'post',
    data: data,
  });
}

// -----------------------------------------------------
// 编辑规则分类
export function editCate(data) {
  return request({
    url: '/xmadmin/news.cate/edit',
    method: 'post',
    data: data,
  });
}
// 新增规则分类
export function addCate(data) {
  return request({
    url: '/xmadmin/news.cate/add',
    method: 'post',
    data: data,
  });
}

// 获取违规公示时间
export function getViolationNotice(data) {
  return request({
    url: '/xmadmin/system.config/getViolationNotice',
    method: 'get',
    params: data,
  });
}
// 编辑新增违规公示时间
export function violationNotice(data) {
  return request({
    url: '/xmadmin/system.config/violationNotice',
    method: 'post',
    data: data,
  });
}

// ----------------------------------------------------
// seo设置
export function getSeoList(data) {
  return request({
    url: '/xmadmin/settings.seo/getList',
    method: 'get',
    params: data,
  });
}
// 编辑TDK
export function editSettingSeo(data) {
  return request({
    url: '/xmadmin/settings.seo/editSetting',
    method: 'post',
    data: data,
  });
}
// 获取SEO操作日志下拉列表
export function getConditionListSeo(data) {
  return request({
    url: '/xmadmin/settings.seo/getConditionList',
    method: 'get',
    params: data,
  });
}

// seo设置
export function getLogListSeo(data) {
  return request({
    url: '/xmadmin/settings.seo/getLogList',
    method: 'get',
    params: data,
  });
}

// 编辑新闻内容类别
export function editClassifyType(data) {
  return request({
    url: '/xmadmin/news.news/editClassifyType',
    method: 'post',
    data: data,
  });
}

// 新闻内容类别操作记录
export function operationLogCateList(data) {
  return request({
    url: '/xmadmin/news.news/getClassifyLog',
    method: 'get',
    params: data,
  });
}
