
// 组件
import SearchBox from '@/pages/front-desk/business-support/plat-rule/components/SearchBox.vue';
import SideNavigation from '@/pages/front-desk/business-support/plat-rule/components/SideNavigation.vue';
import SideNavChose from '@/pages/front-desk/business-support/plat-rule/components/SideNavChose.vue';
import Footer from '@/pages/front-desk/components/footer/Footer.vue'; //底部
// api
import { getJobList, getJobMessage } from '@/api/client-api/front_desk.js';

export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  name: 'PositionList',
  components: {
    SearchBox,
    SideNavigation,
    SideNavChose,
    Footer,
  },
  data() {
    return {
      jobListSide: [], // 招聘信息侧边栏
      jobChoseList: [], // 选中展示的招聘列表
      searchKeyword: '', // 搜索的关键词
      currently: '-1', // 侧边导航栏标识
    };
  },
  // 需要seo的
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';

    try {
      const [resTdk] = await Promise.all([
        // 获取TDK
        app.$getSeoInfo({ id: '40' }),
      ]);
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return {
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/recruitment/PositionList.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        Title,
        Keywords,
        Description,
      };
    }
  },
  mounted() {
    this.scrollToTop();
    this.getJobListSide();
  },
  methods: {
    // 获取侧边栏招聘信息
    getJobListSide() {
      getJobList().then((res) => {
        if (res.code == 1) {
          const modifiedArray = res.data.map((item) => {
            const modifiedChildren = item.children.map((child) => {
              return {
                ...child,
                allid: `child-${child.id}`,
                otherMsg: [
                  `发布于${child.publish_time}`,
                  child.team,
                  child.address,
                ],
              };
            });
            this.jobChoseList = [...this.jobChoseList, ...modifiedChildren];
            return {
              ...item,
              children: modifiedChildren,
              allid: `parent-${item.team_id}`,
              id: item.team_id,
              title: item.team_name,
            };
          });
          this.jobListSide = modifiedArray;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 选中的招聘信息
    choseSpecific(cid) {
      this.$router.push('/positiondetail/' + cid);
    },
    // 搜索关键词
    searchWord(keyword) {
      this.currently = '';
      this.searchKeyword = keyword;
      let options = {
        keyword,
      };
      getJobMessage(options).then((res) => {
        if (res.code == 1) {
          let dataType = Object.prototype.toString.call(res.data).slice(8, -1);
          if (
            dataType == 'String' ||
            (dataType == 'Array' && res.data.length == 0)
          ) {
            this.jobChoseList = [];
          }
          if (dataType == 'Array' && res.data.length != 0) {
            const modifiedArray = res.data.map((item) => {
              return {
                ...item,
                otherMsg: [
                  `发布于${item.publish_time}`,
                  item.team,
                  item.address,
                ],
              };
            });
            this.jobChoseList = modifiedArray;
          }
        } else {
          this.jobChoseList = [];
          this.$message.warning(res.msg);
        }
      });
    },
    // 点击父级
    clickFirstLevel(pid, ptitle) {
      this.currently = '-1';
      this.searchKeyword = '';
      let options = {
        id: pid,
      };
      getJobList(options).then((res) => {
        if (res.code == 1) {
          const modifiedArray = res.data[0].children.map((item) => {
            return {
              ...item,
              otherMsg: [`发布于${item.publish_time}`, ptitle, item.address],
            };
          });
          this.jobChoseList = modifiedArray;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 点击子集
    clickSecondLevel(allid, cid) {
      this.searchKeyword = '';
      this.currently = allid;
      this.$router.push('/positiondetail/' + cid);
    },
  },
};
