
export default {
  name: 'Carousel',
  props: {
    // 轮播图片列表
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      type: 'images_pc', //images_pc-pc图片 images_mobile-手机图片
    };
  },
  computed: {},
  methods: {
    /**
     * 页面触发
     */
    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        this.type = 'images_mobile';
      } else {
        this.type = 'images_pc';
      }
    },
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      let box = document.querySelector(
        '.carousel-box-style .el-carousel__container'
      );
      let startPoint = 0;
      let stopPoint = 0;
      //重置坐标
      let resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener('touchstart', function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener('touchmove', function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener('touchend', function (e) {
        console.log('1：' + startPoint);
        console.log('2：' + stopPoint);
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.carousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.carousel.prev();
          return;
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener(
        'resize',
        this._throttle(this.checkScreenWidth, 150)
      );
      this.slideBanner();
    });
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.checkScreenWidth);
    }
  },
};
