
import ArticleCards from './ArticleCards';
export default {
  components: {
    ArticleCards,
  },
  data() {
    return {
      curHmoeNewsList: this.hmoeNewsList || [],
      showNum: 4, //展示个数
    };
  },
  props: {
    // 处理后的列表
    hmoeNewsList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    /**
     * 页面触发
     */
    arrowClick(val) {
      if (val === 'next') {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
    setList(list, num = 4) {
      const arr = [];
      // 将列表分成 parseInt(i/4) + 1 份
      for (let i = 0; i < list?.length; i += num) {
        arr?.push(list.slice(i, i + num));
      }
      return arr || [];
    },
    // -----------------------------------------------------
    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 769) {
        if (this.showNum == 2) return;
        this.smallScreenFunction();
      } else if (screenWidth >= 769 && screenWidth < 1200) {
        if (this.showNum == 3) return;
        this.mediumScreenFunction();
      } else {
        if (this.showNum == 4) return;
        this.largeScreenFunction();
      }
    },
    smallScreenFunction() {
      // 手机
      this.setCurHmoeNewsList(2);
    },
    mediumScreenFunction() {
      // 平板
      this.setCurHmoeNewsList(3);
    },
    largeScreenFunction() {
      // pc
      this.setCurHmoeNewsList(4);
    },
    // 重组新闻动态数据结构
    setCurHmoeNewsList(num = 4) {
      if (this.showNum == num) return;
      this.showNum = num;
      let list = JSON.parse(JSON.stringify(this.curHmoeNewsList || [])) || [];
      let arr = [].concat.apply([], list);
      this.curHmoeNewsList = this.setList(arr, this.showNum) || [];
    },
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      let box = document.querySelector('.carou-model .el-carousel__container');
      let startPoint = 0;
      let stopPoint = 0;
      //重置坐标
      let resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener('touchstart', function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener('touchmove', function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener('touchend', function (e) {
        console.log('1：' + startPoint);
        console.log('2：' + stopPoint);
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.cardShow.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.cardShow.prev();
          return;
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener(
        'resize',
        this._throttle(this.checkScreenWidth, 150)
      );
      this.slideBanner();
    });
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.checkScreenWidth);
    }
  },
};
