
// 变量
import { searchList, apiSearchType } from './js/configinfo';
// api
import {
  modifyingFieldsBanner, //banner相关
  modifyingFieldsConfig, //系统设置相关
  modifyingFieldsHotSearch, //热搜词相关
  modifyingFieldsCoreAdvantages, //核心优势相关
  modifyingFieldsNews, //新闻相关
  getUser, //获取操作列表
  modifyingFields,
  modifyingFieldsOpinion,
} from '@/api/client-api/backstageapi.js';
export default {
  name: 'OperationSearch',
  data() {
    return {
      // ---------------
      // 注册API
      modifyingFieldsBanner,
      modifyingFieldsConfig,
      modifyingFieldsHotSearch,
      modifyingFieldsCoreAdvantages,
      modifyingFieldsNews,
      apiSearchType,
      modifyingFields,
      modifyingFieldsOpinion,
      // ---------------
      // 搜索选中数据
      searchData: {
        modifying_method: '', //修改方式
        old_sort: '', //排序序号
        modifying_fields: '', //修改字段
        operator_id: '', //操作人
        time: [], //时间范围搜索
      },
      // 修改字段下拉列表（后端数据）
      modifyFieldOptions: {},
      // 操作人下拉列表（后端数据）
      operatorOptions: [],
    };
  },
  props: {
    // 当前选中的标签数据
    curMain: {
      type: Object,
      default: () => {},
    },
    // 列表总数/充当排序
    total: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    curMain: {
      handler(n) {
        // 标签改变之后需要干啥这里定
        this.getModifyingFields(n);
      },
      deep: true,
    },
  },
  computed: {
    // 根据不同的标签区分用哪几个搜索
    curSearchList() {
      return searchList[this.curMain?.searchlisttype];
    },
    // 修改方式下拉列表
    modifyWayOptions() {
      let basicArr = [
        { label: '新增', value: '1' },
        { label: '删除', value: '2' },
        { label: '修改', value: '3' },
      ];
      let moveArr = [{ label: '移动', value: '5' }];
      if (this.curMain?.operateMove) return [...basicArr, ...moveArr];
      return basicArr;
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 筛选
    changeSearch() {
      let newObj = this.filterEmptyKey(this.searchData);
      // 特殊处理时间范围
      if (newObj?.time?.length > 0) {
        newObj.create_time_start = newObj?.time?.[0];
        newObj.create_time_end = newObj?.time?.[1];
        delete newObj.time;
      }
      this.$emit('chang-search', newObj);
    },
    // 获取修改字段列表
    async getModifyingFields() {
      const options = {
        cate_menu: this.curMain?.cate_menu,
      };
      try {
        let res =
          (await this?.[apiSearchType?.[this.curMain?.apiType]](options)) || {};
        if (res?.code == 1) {
          this.modifyFieldOptions = res?.data || {};
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取操作列表
    async getUserList() {
      const options = {};
      try {
        let res = await getUser(options);
        if (res?.code == 0) {
          this.operatorOptions = res?.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 重置筛选
    reSearch() {
      this.searchData = {
        modifying_method: '', //修改方式
        old_sort: '', //排序序号
        modifying_fields: '', //修改字段
        operator_id: '', //操作人
        time: [], //时间范围搜索
      };
      this.changeSearch();
    },
  },
  mounted() {
    this.getModifyingFields();
    this.getUserList();
  },
};
