
const TELREG = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
// const EMAILREG = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/; // 邮箱正则
const EMAILREG = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/; // 邮箱正则
import { submitUserMsg } from '@/api/client-api/front_desk.js';
export default {
  name: 'ApplyMessage',
  props: {},
  data() {
    return {
      basicInfo: {
        name: '', // 姓名
        sex: '', // 性别
        phone: '', // 手机号
        email: '', // 邮箱
        annex: '', // 简历
      },
      nameEmpty: false, // 姓名盒子为空
      sexEmpty: false, // 性别盒子未空
      phoneEmpty: false, // 手机号盒子未空
      emailEmpty: false, // 邮箱盒子未空
      annexEmpty: false, // 简历盒子未空
      // 性别选择下拉框
      sexOptions: [
        { label: '女', value: 1 },
        { label: '男', value: 2 },
      ],
      annexName: '', // 简历名称
      percentage: 0, // 简历上传进度
      onProgressTime: false, // 简历上传开始
      isReadPrivacy: false, // 是否勾选隐私政策
      jobID: '', // 工作招聘ID
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.jobID = this.$route.params.id;
    }
  },
  watch: {
    'basicInfo.name'(val) {
      if (val.trim() !== '') {
        this.nameEmpty = false;
      }
    },
    'basicInfo.sex'(val) {
      if (val !== '' && val != undefined) {
        this.sexEmpty = false;
      }
    },
    'basicInfo.phone'(val) {
      if (val.trim() !== '' && TELREG.test(val.trim())) {
        this.phoneEmpty = false;
      }
    },
    'basicInfo.email'(val) {
      if (val.trim() !== '' && EMAILREG.test(val.trim())) {
        this.emailEmpty = false;
      }
    },
    'basicInfo.annex'(val) {
      this.annexEmpty = false;
    },
  },
  methods: {
    // 点击隐私政策
    choseRead() {
      this.isReadPrivacy = !this.isReadPrivacy;
    },
    // 去阅读隐私政策
    gotoRead() {
      window.open('https://home.ximu.cn/platrule/85');
    },
    // 上传简历文件前限制
    beforeUpload(file) {
      const fileMaxSize = 10 * 1024 * 1024;
      const fileSuffixName = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'];

      if (file.size > fileMaxSize) {
        this.$message.warning('请将您的简历文件控制在10M以内');
        return false;
      }

      if (fileSuffixName.includes(file.name.split('.')[1])) {
      } else {
        this.$message.warning('暂不支持该格式文件');
        return false;
      }
    },
    // 上传中
    onProgress(event, file) {
      this.onProgressTime = true;
      this.percentage = Math.floor(event.percent) - 2;
      this.annexName = file.name;
    },
    // 上传成功
    onSuccess(res) {
      if (res.code == 1) {
        this.percentage = 100;
        this.basicInfo.annex = res.data.link;
      }
    },
    // 上传失败
    onError() {
      this.$message.warning('上传失败');
      this.onProgressTime = false;
      this.percentage = 0;
    },
    // 删除简历文件
    delFile() {
      this.onProgressTime = false;
      this.basicInfo.annex = '';
    },
    // 提交简历
    submit() {
      if (this.basicInfo.name.trim() == '') {
        this.nameEmpty = true;
      }
      if (this.basicInfo.sex == '' || this.basicInfo.sex == undefined) {
        this.sexEmpty = true;
      }
      if (
        this.basicInfo.phone.trim() == '' ||
        !TELREG.test(this.basicInfo.phone.trim())
      ) {
        this.phoneEmpty = true;
      }
      if (
        this.basicInfo.email.trim() == '' ||
        !EMAILREG.test(this.basicInfo.email.trim())
      ) {
        this.emailEmpty = true;
      }
      if (this.basicInfo.annex == '') {
        this.annexEmpty = true;
      }
      if (
        this.basicInfo.name == '' ||
        this.basicInfo.sex == '' ||
        this.basicInfo.sex == undefined ||
        !TELREG.test(this.basicInfo.phone.trim()) ||
        this.basicInfo.phone == '' ||
        this.basicInfo.email == '' ||
        !EMAILREG.test(this.basicInfo.email.trim()) ||
        this.basicInfo.annex == ''
      ) {
        return;
      }
      if (this.isReadPrivacy == false) {
        this.$message.warning('请同意隐私政策');
        return;
      }
      let options = {
        name: this.basicInfo.name,
        sex: this.basicInfo.sex,
        phone: this.basicInfo.phone,
        email: this.basicInfo.email,
        attachment: this.basicInfo.annex,
        recruitmentId: this.jobID,
      };
      submitUserMsg(options).then((res) => {
        if (res.code == 1) {
          this.$emit('submit-msg');
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
