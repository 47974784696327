export const tableConfig = [
  {
    text: '规则标题',
    filed: 'title',
    width: '110px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '发布状态',
    filed: 'home_page_status',
    width: '76px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '发布时间',
    filed: 'publish_time',
    width: '160px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '修改时间',
    filed: 'update_time',
    width: '160px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '操作人',
    filed: 'operator',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '状态',
    filed: 'status',
    width: '66px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '备注',
    filed: 'remarks',
    width: '120px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '120px',
    isSort: false,
    isEdit: true,
  },
];
