
// api
import { login } from '@/api/client-api/backstageapi.js';
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        keep_login: '',
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '用户名不能为空' },
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' },
        ],
        keep_login: [
          { required: true, trigger: 'blur', msg: '必须选择是否保持登录' },
        ],
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      obj: {},
      loadingStatus: true,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    // 跳转忘记密码
    goModification() {
      this.$router.push('/modification');
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },

    // 登录
    handleLogin() {
      if (this.loginForm.username == '') {
        this.$message.warning('请输入用户名');
        return;
      }
      if (this.loginForm.password == '') {
        this.$message.warning('请输入密码');
        return;
      }
      if (this.loginForm.keep_login == '') {
        this.$message.warning('请选择登录状态');
        return;
      }
      login(this.loginForm).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.$router.push('/backstage/home');
        }
      });
    },
  },
};
