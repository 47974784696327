export const storeStory = [
  {
    anchorpoint: 'storeTable',
    hasbtn: true,
    title: '商家故事',
    cate_menu: 'store_story',
    apiType: '5',
    searchlisttype: '5',
    tableType: '6',
  },
  {
    anchorpoint: 'operationlog',
    title: '操作日志',
  },
];
