
import ImageShow from '@/pages/front-desk/components/ImageShow.vue';
export default {
  name: 'VideoBox',
  components: { ImageShow },
  props: {
    // 盒子需要的信息
    details: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {},
};
