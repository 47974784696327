
export default {
  name: 'XmUploadFileName',
  props: {
    /**
     * 是否是可以拖拽上传的上传框
     */
    canDrag: {
      type: Boolean,
      default: true,
    },
    /**
     *
     */
    buttonFont: {
      type: String,
      default: '点击上传',
    },
    /**
     * 同一个页面有多个上传时候的区分
     */
    uploadType: {
      type: String,
      default: '',
    },
    /**
     * 编辑页的回显已上传文件
     * 传字符串，逗号分隔
     * 一定是完整路径
     */
    editUploadFile: {
      type: String,
      default: '',
    },
    // 是否自定义操作按钮
    hasCustombutton: {
      type: Boolean,
      default: false,
    },
    /**
     * 限制上传的文件数
     */
    maxFileNumer: {
      type: Number,
      default: 1,
    },
    /**
     * 限制能上传的文件格式
     * 默认只能传png,jpeg,jpg图片
     * eg: '.png, .jpeg, .jpg, .mp4'
     */
    acceptFile: {
      type: String,
      default: '.png, .jpeg, .jpg',
    },
    /**
     * 限制可上传图片的文件数
     */
    limitUploadPicNum: {
      type: Number,
      default: 1000,
    },
    /**
     * 限制可上传视频的文件数
     */
    limitUploadVideoNum: {
      type: Number,
      default: 1000,
    },
    /**
     * 可上传的图片格式文件的最大值
     * 以M为单位的数字
     * 不传默认图片最大不可以超过5M
     */
    picMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 可上传的视频格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认视频最大不可以超过200M
     */
    videoMaxSize: {
      type: Number,
      default: 200,
    },
    /**
     * 视频是否限制上传秒数
     * 默认不限制
     */
    videoLimitSecond: {
      type: Boolean,
      default: false,
    },
    /**
     * 视频可上传的最长秒数
     * 以 s(秒) 为单位的数字
     * 不传默认视频最长不可以超过30s
     */
    videoMaxSecond: {
      type: Number,
      default: 30,
    },
    /**
     * 可上传的word格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认word文件最大不可以超过5M
     */
    wordMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 可上传的pdf格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认pdf文件最大不可以超过5M
     */
    pdfMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 可上传的excel格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认excel文件最大不可以超过5M
     */
    excelMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 可上传的ppt格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认ppt文件最大不可以超过5M
     */
    pptMaxSize: {
      type: Number,
      default: 5,
    },
    // 是否需要自定义提示
    isCustomTip: {
      type: Boolean,
      default: false,
    },
    /**
     * 以kb为单位的上传
     * 值的单位为kb
     * eg: 传500限制500kb
     */
    kbUpload: {
      type: Number,
      default: -1,
    },
    /**
     * 是否需要视频限制
     * 默认不需要限制
     */
    hasVideoRestrictions: {
      type: Boolean,
      default: false,
    },
    /**
     * 只能上传一个的情况下，是否能替换
     */
    isReplacement: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      fileList: [], // 上传的文件列表
      oldItemList: [], //存储回显的旧列表，可能用来替换
      disabledUpload: false, // 是否禁用
      percentage: 0, // 文件上传进度
      onProgressTime: false, // 文件上传开始
      onProgressFileName: '', // 上传中的文件名
      imgUrl: '', // 默认展示的图片/视频
      fileType: '', // 当前上传文件的类型

      // 预览相关
      showPreviewimg: false, // 预览图片弹窗标识
      previewImgUrl: '', // 预览图片

      showPreviewdoc: false, // 预览文档弹窗标识

      userShowTime: 0, // 使用回显函数次数
    };
  },
  mounted() {},
  watch: {
    // 编辑时回显的数据
    editUploadFile: {
      immediate: true,
      handler(val) {
        if (
          val !== '' &&
          this.userShowTime == 0 &&
          val.substring(0, 6) == 'https:'
        ) {
          this.showEditFile(val);
        }
      },
    },
  },
  filters: {
    uploadFileType(val) {
      if (val == 'excel') {
        return require('@/static/assets/svg/excel.svg');
      }
      if (val == 'pdf') {
        return require('@/static/assets/svg/pdf.svg');
      }
      if (val == 'word') {
        return require('@/static/assets/svg/word.svg');
      }
      if (val == 'zip') {
        return require('@/static/assets/svg/zip.svg');
      }
      if (val == 'ppt') {
        return require('@/static/assets/svg/ppt.svg');
      }
    },
  },
  methods: {
    //预览图片回调
    showPreviewimgFunc(key) {
      let fileType = key.split('.').pop();
      let isImage = this.matchType(fileType).toLowerCase() == 'image';
      let isVideo = this.matchType(fileType).toLowerCase() == 'video';
      if (isImage || isVideo) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
      this.previewImgUrl = this.$options.filters.imgbaseurl(key);
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    /**
     * 上传前钩子
     * 1.过滤不可上传的文件类型 props: acceptFile
     * 2.限制图片大小 props: picMaxSize
     * 3.限制视频 props: videoMaxSize(大小) videoLimitSecond(是否限制秒数) videoMaxSecond(限制的秒数)
     * 4.(需要的话继续往后加)
     */
    async beforeUpload(file) {
      let fileNameLength = file.name.split('.').length;
      this.fileType = this.matchType(file.name.split('.')[fileNameLength - 1]);
      // 限制KB
      if (this.kbUpload != -1 && file.size > this.kbUpload * 1024) {
        this.$message.warning('上传图片大小不能超过' + this.kbUpload + 'KB');
        return Promise.reject();
      }
      // 限制最多上传的总文件数

      if (this.fileList.length >= this.maxFileNumer) {
        this.$message.warning('最多上传' + this.maxFileNumer + '个文件');
        return Promise.reject();
      }

      // 限制最多上传的图片文件数
      if (
        this.fileType == 'image' &&
        this.fileList.filter((item) => item.type === 'image').length >=
          this.limitUploadPicNum
      ) {
        this.$message.warning('最多可上传' + this.limitUploadPicNum + '张图片');
        return Promise.reject();
      }

      // 限制最多上传的视频文件数
      if (
        this.fileType == 'video' &&
        this.fileList.filter((item) => item.type === 'video').length >=
          this.limitUploadVideoNum
      ) {
        this.$message.warning(
          '最多可上传' + this.limitUploadVideoNum + '个视频'
        );
        return Promise.reject();
      }

      // 自定义限制的可上传的文件格式字符串（后缀们）
      let limitType = this.acceptFile.replace(/ /g, '').replace(/\./g, '');
      if (limitType.includes(file.name.split('.')[fileNameLength - 1])) {
      } else {
        this.$message.warning('请上传' + limitType + '格式文件');
        return Promise.reject();
      }

      // 限制图片的大小
      if (
        this.fileType == 'image' &&
        file.size > this.picMaxSize * 1024 * 1024
      ) {
        this.$message.warning('上传图片大小不能超过' + this.picMaxSize + 'M');
        return Promise.reject();
      }

      // 是否需要限制视频
      if (this.hasVideoRestrictions) {
        // 限制视频的大小和秒数
        if (this.fileType == 'video') {
          if (file.size > this.videoMaxSize * 1024 * 1024) {
            this.$message.warning(
              '上传视频大小不能超过' + this.videoMaxSize + 'M'
            );
            return Promise.reject();
          }

          if (this.videoLimitSecond == false) return;

          let url = URL.createObjectURL(file);
          let audioElement = new Audio(url);
          let maxLengthSecond = this.videoMaxSecond;

          function dedmetadata() {
            return new Promise((resolve, reject) => {
              audioElement.onloadedmetadata = () => {
                let duration = parseInt(audioElement.duration, '视频的秒数'); //时长为秒，取整
                if (duration > maxLengthSecond) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              };
            });
          }

          const res = await dedmetadata();
          if (res) {
            this.$message.warning(
              '上传视频不能超过' + this.videoMaxSecond + 's'
            );
            return Promise.reject();
          }
        }
      }

      // 限制word文档的大小
      if (
        this.fileType == 'word' &&
        file.size > this.wordMaxSize * 1024 * 1024
      ) {
        this.$message.warning(
          '上传word文档大小不能超过' + this.wordMaxSize + 'M'
        );
        return Promise.reject();
      }

      // 限制pdf文档的大小
      if (this.fileType == 'pdf' && file.size > this.pdfMaxSize * 1024 * 1024) {
        this.$message.warning(
          '上传pdf文档大小不能超过' + this.pdfMaxSize + 'M'
        );
        return Promise.reject();
      }

      // 限制excel文档的大小
      if (
        this.fileType == 'excel' &&
        file.size > this.excelMaxSize * 1024 * 1024
      ) {
        this.$message.warning(
          '上传excel文档大小不能超过' + this.excelMaxSize + 'M'
        );
        return Promise.reject();
      }

      // 限制ppt文档的大小
      if (this.fileType == 'ppt' && file.size > this.pptMaxSize * 1024 * 1024) {
        this.$message.warning(
          '上传ppt文档大小不能超过' + this.pptMaxSize + 'M'
        );
        return Promise.reject();
      }

      // 直接替换
      if (this.maxFileNumer == 2 && this.isReplacement) {
        this.fileList = [];
      }
      // 判断是否有同名文件
      // if (this.fileList.length !== 0) {
      //   const hasSameName = this.fileList.some(item => item.name === file.name);
      //   if (hasSameName == true) {
      //     // 有同名文件
      //   } else {
      //     // 无同名文件
      //   }
      // }
    },
    /**
     * 上传中钩子
     * imgUrl 上传中需要展示的默认图片/视频
     * onProgressTime 为true文件上传开始(页面显示上传中样式的判断条件)
     * onProgressFileName 当前用户上传的文件的文件名
     * percentage 上传进度
     */
    onProgress(event, file) {
      this.disabledUpload = true;
      this.imgUrl = URL.createObjectURL(file.raw);
      // this.fileType = this.matchType(file.name.split('.')[0]);
      this.onProgressTime = true;
      this.onProgressFileName = file.name;
      this.percentage = Math.floor(event.percent) - 2;
    },
    /**
     * 上传成功钩子
     * percentage 上传进度
     * onProgressTime false不显示上传中样式
     * fileList 已上传成功的文件列表
     */
    onSuccess(res, file, fileList) {
      if (res.code == 1) {
        this.percentage = 100;
        this.onProgressTime = false;
        let uploadSuccessFile = {
          type: this.fileType, // 文件类型
          preview: this.imgUrl, //
          name: this.onProgressFileName, // 用户上传文件的文件名
          link: res.data.link, // 后端返回的
        };
        this.fileList.push(uploadSuccessFile);

        let linkFileList = this.fileList.map((item) => item.link);
        this.$emit('upload-file', linkFileList.toString(), this.uploadType);
        this.disabledUpload = false;
      }
      if (res.code != 1) {
        this.onProgressTime = false;
        this.disabledUpload = false;
        fileList = fileList.pop();
        this.$message.error(res.msg);
      }
    },
    // 上传失败钩子
    onError(err, file) {
      this.$message.error(file.name + '上传失败');
      this.onProgressTime = false;
      this.disabledUpload = false;
      // 只能上传一个，直接替换失败的情况
      if (this.isReplacement) {
        this.fileList = JSON.parse(JSON.stringify(this.oldItemList || []));
      }
    },
    // 删除已上传文件
    delFile(delFileLink) {
      this.fileList = this.fileList.filter((item) => item.link !== delFileLink);
      let linkFileList = this.fileList.map((item) => item.link);
      this.$emit('upload-file', linkFileList.toString(), this.uploadType);
    },
    // 回显已成功上传的文件
    showEditFile(val) {
      this.userShowTime++;
      let arr = val.split(',').map((item) => {
        const fileName = item.trim();
        const type = this.matchType(fileName.split('.').pop());
        const preview = fileName;
        const name = fileName.split('/').pop();
        const link = fileName.split('/').pop();

        return {
          type: type,
          preview: preview,
          name: name,
          link: link,
        };
      });
      this.fileList = arr;
      if (this.isReplacement) {
        this.oldItemList = JSON.parse(JSON.stringify(this.fileList || []));
      }

      let linkFileList = arr.map((item) => item.link);
      this.$emit(
        'upload-file',
        linkFileList.toString(),
        this.uploadType,
        'noTips'
      );
    },
  },
};
