
// 组件
import Footer from '@/pages/front-desk/components/footer/Footer'; //底部
import NewsDetails from '../components/NewsDetails'; //新闻详情
import TheLatestNews from '../components/TheLatestNews'; //最新新闻列表
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  components: { Footer, NewsDetails, TheLatestNews },
  data() {
    return {
      breadList: [
        { name: '公司新闻', link: '/news' },
        { name: '文章详情', link: '' },
      ],
    };
  },
  // 需要seo
  async asyncData(app) {
    let detailsInfo = {}; //新闻详情数据
    let params = app?.route?.params || {};
    let articleList = []; //新闻列表

    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    let tdkInfo = {};
    try {
      const detailsOptions = {};
      const newsListOptions = {
        classify: 'dynamic_company',
        pageNum: 1,
        pageSize: 4,
      };
      if (params?.id) {
        detailsOptions.id = params?.id;
      }
      const [resNewsDetails, resArticleList, resTdk] = await Promise.all([
        // 新闻详情
        app.$getDetail(detailsOptions),
        // 新闻详情内的新闻列表
        app.$getArticleList(newsListOptions),
        // 获取TDK
        app.$getSeoInfo({ id: '42' }),
      ]);
      // 新闻详情
      if (resNewsDetails?.code == 1) {
        detailsInfo = resNewsDetails?.data?.list || {};
      }
      // 新闻详情内的新闻列表
      if (resArticleList?.code == 1) {
        articleList = resArticleList?.data?.list || [];
      }
      // 详情TDK(后台数据)
      if (resTdk?.code == 1) {
        tdkInfo = resTdk?.data?.[0] || {};
      }

      if (detailsInfo?.title) {
        // T- 标题
        Title += detailsInfo?.title;
        // K-关键字
        Keywords += detailsInfo?.title;
      }
      // T- 标题
      if (tdkInfo?.title) {
        Title += tdkInfo?.title;
      }
      // K-关键字
      if (tdkInfo?.keyword) {
        Keywords += tdkInfo?.keyword;
      }

      // D-描述
      if (detailsInfo?.description) {
        Description += detailsInfo?.description?.substring(0, 72);
      }
      return {
        detailsInfo,
        articleList,
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/home-page/index.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        detailsInfo,
        articleList,
        Title,
        Keywords,
        Description,
      };
    }
  },
};
