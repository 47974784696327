
// 变量
import { industryBackstage } from './js/industrybackstage';
// 组件
import ImgBanner from '@/pages/back-stage/components/ImgBanner.vue'; //banner图设置
import OpinionTable from './components/OpinionTable.vue'; //意见征集内容设置
import SolicitationViews from './components/solicitation-views-data/index.vue'; //意见收集数据
import SetPlatformRules from './components/set-platform-rules/index.vue'; //平台规则设置
import OperationLog from '@/pages/back-stage/components/operation-Log/OperationLog.vue'; //操作日志

import AddOpinionsCollect from './components/AddOpinionsCollect.vue'; // 新增/编辑意见征集弹窗
// api
import {
  getOpinionList,
  getViolationNotice,
  violationNotice,
} from '@/api/client-api/backstageapi.js';
export default {
  components: {
    ImgBanner,
    OpinionTable,
    SolicitationViews,
    SetPlatformRules,
    AddOpinionsCollect,
    OperationLog,
  },
  data() {
    return {
      mainList: industryBackstage, //盒子列表
      // 表格数据
      opinionTableData: [], //表格数据
      // 意见征集内容设置
      opinion: {
        page: 1,
        limit: 10,
        count: 0,
      }, //分页
      // 公示时间
      ublicNotice: [], //违规公示时间
      // 弹框
      hasRulesTypeDialog: false, //控制平台规则设置的新增分类弹框
      hasAddOpinion: false, // 意见征集弹窗状态
      dialogTitle: '', // 意见征集弹窗标题
      rowInfo: {}, // 当前选中的表格行数据
    };
  },
  mounted() {
    this.getOpinionList('opinion');
    this.getViolationNotice();
  },
  methods: {
    /**
     * 页面触发
     */
    changeUblicNotice() {
      const options = {
        start_time: this.ublicNotice[0],
        end_time: this.ublicNotice[1],
      };
      violationNotice(options).then((res) => {
        if (res?.code == 1) {
          this.$message.success(res.msg);
        }
      });
    },
    // 违规公示时间
    async getViolationNotice() {
      try {
        let res = await getViolationNotice();
        if (res?.code == 1) {
          if (res?.data?.start_time && res?.data?.end_time) {
            this.ublicNotice = [res?.data?.start_time, res?.data?.end_time];
            console.log(this.ublicNotice, 'this.ublicNotice ');
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    // -------------------------------------------------
    // 意见征集内容设置相关
    // -----------------------------------------
    // 平台规则相关
    // 打开子组件中的新增分类弹框
    handleAddRulesType() {
      this.hasRulesTypeDialog = true;
    },
    // -----------------------------------------
    // 分页切换
    goPages(page, type) {
      this[type].page = page;
      this.getOpinionList(type);
    },
    // 获取意见征集内容设置
    async getOpinionList(type) {
      const options = {
        pageNum: this[type]?.page,
        pageSize: this[type]?.limit,
      };
      let res = await getOpinionList(options);
      if (res?.code == 1) {
        this[type + 'TableData'] = res?.data?.list || [];
        this[type].count = res?.data?.total || 0;
      }
    },
    // 成功删除列表某个数据
    handleDeleteTable(type) {
      // 如果不是在第一页且表格数据只有一条被删除的情况
      if (this[type + 'TableData']?.length == 1 && this[type]?.page > 1) {
        this[type].page = this[type]?.page - 1;
      }
      this.getOpinionList(type);
    },
    /**
     * 意见征集相关
     */
    // 打开意见征集弹窗
    handleAddOpinionType() {
      this.dialogTitle = '新增意见征集';
      this.hasAddOpinion = true;
    },
    // 关闭意见征集弹窗
    handleCancel() {
      this.hasAddOpinion = false;
      this.rowInfo = {};
    },
    // 确认新增/编辑征集
    handleConfirm() {
      this.handleCancel(); // 关闭弹窗
      this.getOpinionList('opinion'); // 重新获取征集数据
    },
    // 意见征集表格点击编辑按钮
    editOpinionRow(row) {
      this.rowInfo = row;
      this.dialogTitle = '编辑意见征集';
      this.hasAddOpinion = true;
    },
  },
};
