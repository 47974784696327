
import draggable from 'vuedraggable';
export default {
  name: 'CateShowBox',
  components: {
    draggable,
  },
  props: {
    // 盒子类型名称(自用)
    typeName: {
      type: String,
      default: '',
    },
    // 当前盒子分类(后端用) learning_recommend 选品推荐品类, learning_operating 运营干货品类
    cateMenu: {
      type: String,
      default: '',
    },
    // 获取的数据列表
    dataList: {
      type: Array,
      default: () => [],
    },
    // 操作状态 ''不可操作 edit编辑 move移动
    operateType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cateList: [], // 拷贝的品类列表
      delTipDialog: false, // 删除二次提示弹窗状态
      delTipContent: '', // 删除提示语
      delSort: '', // 需要删除的元素下标
      listError: false, // 当前列表有无错误提示(有一个就true)
      delClearErr: '', // 删除品类的错误提示
    };
  },
  watch: {
    // 拷贝品类列表
    dataList(data) {
      if (data) {
        this.cateList = JSON.parse(JSON.stringify(data));
      }
    },
    // 操作状态改变，重新渲染
    operateType(data) {
      this.cateList = JSON.parse(JSON.stringify(this.dataList));
    },
  },
  computed: {
    canEdit() {
      if (this.operateType == 'edit' && !this.listError) return true;
      return false;
    },
  },
  methods: {
    /**
     * 删除品类
     * @param {String} item 要删除的品类数据
     * @param {Number} num 要删除品类的数据下标
     */
    delCate(item, num) {
      this.delClearErr = item.errTipFont;
      if (!item.id) {
        this.cateList.splice(num, 1);
        this.delJudgeClearErrTip(this.delClearErr);
        return;
      }
      this.delTipContent =
        '确认删除“' +
        item.name +
        '”品类？与之关联的文章等信息都将被删除！删除操作不可逆，请再次确认是否删除“' +
        item.name +
        '”类目！';
      this.delSort = num;
      this.delTipDialog = true;
    },
    // 确认删除
    sureDel() {
      this.delTipDialog = false; // 关闭删除提示弹窗
      this.cateList.splice(this.delSort, 1); // 数组删除指定位置元素
      this.delJudgeClearErrTip(this.delClearErr);
    },
    // 关闭弹窗
    closeDialog() {
      this.delTipDialog = false; // 关闭删除提示弹窗
      this.delTipContent = ''; // 重置提示弹窗内容
      this.delSort = ''; // 重置选中删除数据下标
    },
    // 输入
    checkCateItem(name, index) {
      let aa = '';
      let hasErr = false; // 是否有问题 初始为否false
      for (let i = 0; i < this.cateList.length; i++) {
        if (i != index && this.cateList[i].name == name) {
          this.listError = true;
          hasErr = true;
          aa = i;
          break;
        }
      }
      if (hasErr) {
        this.cateList[index].errTipFont = '品类名已存在';
        if (aa !== '') this.cateList[aa].errTipFont = '品类名已存在';
      } else if (!name) {
        this.listError = true;
        this.cateList[index].errTipFont = '品类名不能为空';
        let sameErr = this.cateList?.findIndex(
          (value) => value.errTipFont == '品类名已存在'
        );
        if (sameErr != -1) {
          this.cateList[sameErr].errTipFont = '';
        }
      } else {
        this.cateList.forEach((cate) => {
          cate.errTipFont = '';
        });
        this.listError = false;
      }
    },
    // 点击新增品类
    addCate() {
      this.judgeCateEmpty();
      let errArr = this.cateList.filter((i) => i.errTipFont);
      if (errArr?.length) {
        this.$message.warning('请修改当前错误');
        return;
      }
      let newCate = {
        name: '',
        errTipFont: '',
      };
      this.cateList.push(newCate);
    },
    // 判断当前品类有无空值
    judgeCateEmpty() {
      this.cateList.forEach((cate) => {
        if (!cate.name?.trim()) {
          this.listError = true;
          cate.errTipFont = '品类名不能为空';
        } else if (cate.errTipFont != '品类名已存在') {
          cate.errTipFont = '';
        }
      });
    },
    /**
     * 删除品类判断清除错误提示
     * @param {String} tip // 删除品类的错误提示
     */
    delJudgeClearErrTip(tip) {
      if (tip == '品类名已存在') {
        let sameErr = this.cateList?.findIndex(
          (value) => value.errTipFont == '品类名已存在'
        );
        if (sameErr != -1) {
          this.cateList[sameErr].errTipFont = '';
        }
        let errArr = this.cateList.filter((i) => i.errTipFont);
        if (!errArr?.length) this.listError = false;
      }
      if (tip == '品类名不能为空') {
        this.listError = false;
      }
    },
    // 编辑/移动点击完成
    editFinish() {
      this.judgeCateEmpty(); // 判断当前品类有无空值
      if (this.listError) {
        this.$message.warning('请修改当前错误');
        return;
      }
      let newArr =
        this.cateList?.map((obj, index) => {
          return { id: obj.id || '', name: obj.name, sort: index };
        }) || [];
      this.$emit('edit-finish', this.typeName, this.cateMenu, newArr);
    },
  },
};
