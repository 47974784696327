// 设置TDK
export const setTdkField = [
  {
    text: '页面',
    filed: 'page_name',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '标题',
    filed: 'title',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '描述',
    filed: 'description',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '关键词',
    filed: 'keyword',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
];
