
// 变量
import { tableConfig } from './js/table_config';
// 组件
import Remarks from './components/Remarks'; //备注
import DataCollection from './components/DataCollection';
// api
import {
  getOpinionRecord,
  deleteOpinionRecord,
} from '@/api/client-api/backstageapi.js';
export default {
  components: { Remarks, DataCollection },
  data() {
    return {
      // 分类下拉
      usersOptions: [
        { label: '全部' },
        { label: '供货商', value: '1' },
        { label: '零售商', value: '2' },
      ],
      userType: '', //分类
      // 表格相关
      curTableField: tableConfig, //当前表格配置
      tableData: [], //表格数据
      rowInfo: {}, //选中的表格数据
      pageNum: 1, //页码
      pageSize: 10, //条数
      total: 0, //总数
      // 弹框
      hasDoubleCheckDialog: false, //控制二次确认弹框
      content: '您确定要删除吗？',

      hasRemarksDialog: false, //控制编辑备注弹框

      hasForDetails: false, //控制查看详情弹框
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    /**
     * 页面触发
     */
    // 分页相关
    goPages(val) {
      this.pageNum = val;
      this.getTableData();
    },
    // -----------------------------
    // 点击“查看详情”
    handleForDetails(row) {
      this.rowInfo = row;
      this.hasForDetails = true;
    },
    // 关闭详情弹框
    closeForDetailsDialog() {
      this.rowInfo = {};
      this.hasForDetails = false;
    },
    // -----------------------------
    // 备注弹框发布编辑
    async upRemark() {
      this.closeRemarksDialog();
      this.getTableData();
    },
    // 点击“备注”
    handleRemark(row) {
      this.rowInfo = row;
      this.hasRemarksDialog = true;
    },
    // 关闭编辑备注弹框
    closeRemarksDialog() {
      this.rowInfo = {};
      this.hasRemarksDialog = false;
    },
    // ------------------------------------
    // 点击“删除”
    handleDelete(row) {
      this.rowInfo = row;
      this.hasDoubleCheckDialog = true;
    },
    // 二次确认弹框取消按钮
    handleCloseDia() {
      this.rowInfo = {};
      this.hasDoubleCheckDialog = false;
      this.content = '您确定要删除吗？';
    },
    // 二次确认弹框确认按钮
    async handleConfigDia() {
      const options = {
        id: this.rowInfo.id,
      };
      try {
        let res = await deleteOpinionRecord(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.handleCloseDia();
          this.getTableData();
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 下拉筛选事件
    changeSearch() {
      this.pageNum = 1
      this.getTableData()
    },
    // 获取数据
    async getTableData() {
      const options = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      if (this.userType) {
        options.type = this.userType;
      }
      let res = await getOpinionRecord(options);
      if (res?.code == 1) {
        this.tableData = res?.data?.list || [];
        this.total = res?.data?.total || 0;
      }
      console.log(res, 'res');
    },
  },
};
