
// 组件
import SingleBanner from '@/pages/front-desk/components/SingleBanner'; //单张banner组件
import Footer from '@/pages/front-desk/components/footer/Footer'; //底部
import ValuesBox from './components/Values'; //价值观
import WhyChoose from './components/WhyChoose'; //为什么选择细目
import ApplicationProcess from './components/ApplicationProcess';
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  components: {
    SingleBanner,
    Footer,
    ValuesBox,
    WhyChoose,
    ApplicationProcess,
  },
  data() {
    return {
      // 面包屑列表
      breadList: [{ name: '加入我们', link: '' }],
      // banner数据
      bannerInfo: {
        images_pc: 'test_upload_office-web_1706149433.png',
        images_mobile: 'test_upload_office-web_1719470988.png',
      },
    };
  },
  // 需要seo的
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    try {
      const [resTdk] = await Promise.all([
        // 获取TDK
        app.$getSeoInfo({ id: '39' }),
      ]);
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return {
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/about-us/join-us/index.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        Title,
        Keywords,
        Description,
      };
    }
  },
};
