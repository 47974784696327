
export default {
  data() {
    return {};
  },
  props: {
    // 流程块状序号
    num: {
      type: [String, Number],
      default: '1',
    },
    // 流畅标题
    title: {
      type: String,
      default: '',
    },
    // 流程内容
    text: {
      type: String,
      default: '',
    },
  },
};
