import Vue from 'vue';
import axios from 'axios';
import { filterEmptyKey } from './utils';
import IMG_ERROR_PLACEHOLDER from './imgConstant';
// 设置缓存过期时间
import storage from '@/utils/storage-time/storagetime.js';

let mixin = {
  data() {
    return {};
  },
  activated() {},
  filters: {
    // 给图片加域名
    imgbaseurl(value) {
      if (!value) return '';
      const procotol = value.substring(0, 4);
      const prefix = procotol == 'http' ? '' : 'https://office.static.ximu.cn/';
      return prefix + value;
    },
    /**
     * 获取存在七牛云上的视频的第一帧图片
     * @param { String } videoSrc 七牛云的视频链接
     */
    getVideoPoster(videoSrc) {
      if (!videoSrc) return '';
      // 如果没有拼前缀，则拼上
      if (!videoSrc.startsWith('http')) {
        videoSrc = 'https://office.static.ximu.cn/' + videoSrc;
      }
      return videoSrc + '?vframe/jpg/offset/1';
    },
  },
  methods: {
    /**
     * 跨域传递信息
     * @param {String} url 跳转位置
     * postMessage(message,targetOrigin)
     * @param {*} message 发送到其它窗口的参数
     * @param {String} targetOrigin 指定哪些窗口能接收到消息事件
     */
    goShopUrl(url) {
      if (process.client) {
        // 秒为单位
        let time = 60 * 60 * 24 * 30;
        // 发送消息
        const _uid = this.generateUserId(10);

        // storage.set('office_uid', _uid, 8)
        if (!storage.get('office_uid')) {
          // 如果 localStorage key为office-storage-name 中的 office_uid 没数据
          storage.set('office_uid', _uid, time);
        }
        // 传递参数
        const options = {
          source: 'ximu-office',
          uid: storage.get('office_uid') || '',
        };
        const goUrl = process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN + url;
        let times;
        const targetWindow = window.open(goUrl);
        times = setTimeout(() => {
          targetWindow.postMessage(
            options,
            process.env.NUXT_ENV.VUE_APP_SHOP_DOMAIN
          );
          clearTimeout(times);
          times = null;
        }, 2000);
      }
    },
    // 随机生成用户ID
    generateUserId(length) {
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // 包含大小写字母和数字的所有字符集合
      var userId = '';

      for (var i = 0; i < length; i++) {
        var randomIndex = Math.floor(Math.random() * characters.length); // 获取随机索引值
        userId += characters[randomIndex]; // 将对应位置上的字符添加到userId变量中
      }

      return userId;
    },
    // 回到顶部
    scrollToTop: function () {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    /**
     * 设置图片属性
     * @param {String} url 图片url
     * @param {String} width 改变图片属性宽度
     * @param {String} height 改变图片属性高度
     * @returns 处理后的图片url
     * 改变图片大小
     */
    changeImgProperties(url, width = '200', height = '200') {
      if (!url) return '';
      const procotol = url.substring(0, 4);
      const prefix = procotol == 'http' ? '' : 'https://office.static.ximu.cn/';
      let finishImg =
        prefix +
        url +
        '?imageView2/0/w/' +
        width +
        '/h/' +
        height +
        '/interlace/1/q/100';
      return finishImg;
    },

    /**
     * 过滤掉对象中值为空的字段
     * @author hukeyi
     * @param {*} obj
     * @returns 有值的字段组成的新对象
     */
    filterEmptyKey(obj) {
      return filterEmptyKey(obj);
    },
    /**
     * 节流函数
     * @param Function func 节流目标函数
     * @param String delay 延迟时间
     * @returns
     */
    _throttle: function (func, delay) {
      let timer;
      return function () {
        let context = this;
        let args = arguments;
        if (!timer) {
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, delay);
        }
      };
    },
    /**
     * 防抖函数
     * @param Function func 目标函数
     * @param String wait 时间
     * @returns
     */
    _debounce: function (func, wait) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    /**
     * 图片加载失败占位
     */
    handleImgError(event) {
      const img = event.target;
      // 用 base64 编码图片作为占位图片，避免出现 onerror 死循环导致递归爆栈
      img.src = IMG_ERROR_PLACEHOLDER;
    },
    // 回到顶部
    scrollToTop: function () {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 内部跳转
    routeJump(url) {
      return url;
    },
    // 出站跳转
    jumpEnv(url, address) {
      if (process.env.NUXT_ENV.NODE_ENV == 'production') {
        return 'https://www.ximu.cn' + url;
      } else if (process.env.NUXT_ENV.NODE_ENV == 'check') {
        return 'http://192.168.2.191:' + address + url;
      } else if (process.env.NUXT_ENV.NODE_ENV == 'release') {
        return 'http://192.168.2.190:' + address + url;
      } else {
        return 'http://192.168.2.188:' + address + url;
      }
    },
    // 通过后缀名作文件判断,有补充自己加
    matchType(fileName) {
      // 后缀获取
      var suffix = '';
      // 获取类型结果
      var result = '';
      try {
        var flieArr = fileName.split('.');
        suffix = flieArr[flieArr.length - 1]?.toLowerCase(); // 保证是小写字母
      } catch (err) {
        suffix = '';
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'jfif', 'webp'];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'image';
        return result;
      }
      // 匹配txt
      var txtlist = ['txt'];
      result = txtlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'txt';
        return result;
      }
      // 匹配 excel
      var excelist = ['xls', 'xlsx'];
      result = excelist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'excel';
        return result;
      }
      // 匹配 word
      var wordlist = ['doc', 'docx'];
      result = wordlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'word';
        return result;
      }
      // 匹配 pdf
      var pdflist = ['pdf'];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'pdf';
        return result;
      }
      // 匹配 ppt
      var pptlist = ['ppt'];
      result = pptlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'ppt';
        return result;
      }
      // 匹配 视频
      var videolist = ['mp4', 'm2v', 'mkv'];
      result = videolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'video';
        return result;
      }
      // 匹配 音频
      var radiolist = ['mp3', 'wav', 'wmv'];
      result = radiolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'radio';
        return result;
      }
      // 其他 文件类型
      result = 'other';
      return result;
    },
    /**
     * 获取存在七牛云上的视频的第一帧图片
     * @param { String } videoSrc 七牛云的视频链接
     */
    getVideoPoster(videoSrc) {
      if (!videoSrc) return '';
      // 如果没有拼前缀，则拼上
      if (!videoSrc.startsWith('http')) {
        videoSrc = 'https://office.static.ximu.cn/' + videoSrc;
      }
      return videoSrc + '?vframe/jpg/offset/1';
    },
    // 链接下载
    down(url, fileName) {
      try {
        if (!url) return '';
        const procotol = url.substring(0, 4);
        const prefix =
          procotol == 'http' ? url : 'https://office.static.ximu.cn/' + url;

        console.log(url, 'prefix');
        axios({
          url: prefix + '?attname=' + fileName,
          method: 'get',
          responseType: 'blob',
        }).then((res) => {
          const filestream = res.data; // 返回的文件流
          const blob = new Blob([filestream], {
            type: res.headers['content-type'],
          });
          const a = document.createElement('a');
          const href = window.URL.createObjectURL(blob); // 创建下载连接
          a.href = href;
          a.download = decodeURI(fileName);
          document.body.appendChild(a);
          // console.log(a)
          // window.open(a.href)
          a.click();
          document.body.removeChild(a); // 下载完移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象
        });
      } catch (error) {
        this.$message({ type: 'warning', message: error });
      }
    },
  },
};
Vue.mixin(mixin);
export default mixin;
