
export default {
  data() {
    return {
      list: [
        {
          title: '坦诚',
          description:
            '态度真诚，表达平和，言行坦率表达真实想法，就事论事，理性沟通',
        },
        {
          title: '实干',
          description: '独立思考，求真务实，奋发向上不断付出努力，立说立行',
        },
        {
          title: '信任',
          description:
            '相认并认可公司使命愿景，共同解决问题，不设边界，与公司共成长',
        },
        { title: '价值', description: '坦诚实干，持续为客户创造价值' },
      ],
    };
  },
};
