import { render, staticRenderFns } from "./LatestDevelopments.vue?vue&type=template&id=0d62fadb&scoped=true"
import script from "./LatestDevelopments.vue?vue&type=script&lang=js"
export * from "./LatestDevelopments.vue?vue&type=script&lang=js"
import style0 from "./LatestDevelopments.vue?vue&type=style&index=0&id=0d62fadb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d62fadb",
  null
  
)

export default component.exports