
import {
  getClassifyType,
  addActicle,
  editActicle,
  getActicleDetails,
} from '@/api/client-api/learning_center';
// 组件
import BasicEditor from './BasicEditor';
export default {
  name: 'AddActicle',
  components: { BasicEditor },
  props: {
    // 表格选中的数据
    rowInfo: {
      type: Object,
      default: () => {},
    },
    /**
     * 哪个文章列表的添加/编辑
     * 后端需要的传值
     * 必传
     * policy_dynamics: 政策动态， dynamic_domestic：国内新闻 ，dynamic_border：跨境资讯，dynamic_company：公司新闻(这三个长一样,新增首页显示状态\转载自)
     * learning_recommend：选品推荐，learning_operating：运营干货(这两一个选择品类一个设置分类)
     * learning_video: 操作视频(标题、视频、分类、状态、发布时间)
     * store_story: 商家故事(标题在左边,新增logo\置顶状态)
     */
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      info: {
        cover: '', // 封面图/视频
        description: '', // 文章描述
        category: '', // 文章品类
        title: '', // 文章标题
        logo: '', // logo
        publishTime: '', // 发布时间
        mainText: '', // 文章正文
        reprint: '', // 转载自
      },
      showSwitch: false, // 显示状态开关
      homeSwitch: false, // 首页显示开关状态
      topSwitch: false, // 置顶开关状态

      categoryList: [], // 品类/分类列表
      editorStatus: 'add', // 富文本状态 add/edit
      acticleKey: 0, // 富文本获取数据需要
    };
  },
  computed: {
    //发布状态 0-未发布 1-已发布
    status() {
      if (this.showSwitch) {
        return '1';
      } else {
        return '0';
      }
    },
    // 文章标题label
    titleLabel() {
      if (this.type == 'learning_video') return '视频标题';
      return '文章标题';
    },
    // 选择分类label
    categoryLabel() {
      if (this.type == 'learning_recommend') return '选择品类';
      if (this.type == 'learning_operating') return '设置分类';
      if (this.type == 'learning_video' || this.type == 'store_story')
        return '选择分类';
    },
  },
  mounted() {
    this.getCategoryList(); // 获取品类列表
    // id存在,编辑弹窗
    if (this.rowInfo?.id) {
      this.editorStatus = 'edit';
      this.getActicleDeatil(); // 获取文章详情
    }
  },
  methods: {
    // 获取品类列表
    async getCategoryList() {
      try {
        let options = {
          classify: this.type,
        };
        const res = await getClassifyType(options);
        if (res?.code == 1) {
          this.categoryList = res?.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 富文本内容改变
    editorChange(val) {
      this.info.mainText = val;
    },

    /**
     * 页面触发
     */
    // 获取文章详情
    async getActicleDeatil() {
      try {
        const res = await getActicleDetails({ id: this.rowInfo?.id });
        if (res?.code == 1) {
          const options = {
            cover: '', // 封面图/视频
            description: '', // 文章描述
            category: '', // 文章品类
            title: '', // 文章标题
            logo: '', // logo
            publishTime: '', // 发布时间
            mainText: '', // 文章正文
            reprint: '', // 转载自
          };
          this.info = JSON.parse(JSON.stringify(this.rowInfo || options));
          this.info.cover = this.rowInfo?.images || ''; // 封面
          this.info.mainText = res?.data?.content || ''; // 文章详情
          this.showSwitch = this.rowInfo.switch; // 显示状态
          this.homeSwitch = this.rowInfo.homeSwitch; // 首页显示状态
          this.topSwitch = this.rowInfo.topSwitch; // 置顶状态

          this.$set(this.info, 'publishTime', this.rowInfo?.publish_time || ''); // 发布时间
          this.$set(this.info, 'category', this.rowInfo?.type || ''); // 文章品类
          this.$set(this.info, 'reprint', this.rowInfo?.reproduction || ''); // 转载自
          this.acticleKey++;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 上传成功回调
    handleUploadFile(link, type) {
      // 封面
      if (type == 'cover') {
        this.info.cover = link;
      }
      // logo
      if (type == 'logo') {
        this.info.logo = link;
      }
    },
    // 点击“确定”
    async handleConfirm() {
      try {
        let mainText = this.info?.mainText
          ?.replace(/<(?!img)(?!video)[^>]+>/g, '')
          ?.trim();
        // 共有
        if (!this.info?.cover || !this.info?.title || !this.info?.publishTime) {
          this.$message.error('还有必填没填');
          return;
        }
        // 特有
        if (
          (this.type != 'learning_video' && !mainText) ||
          (this.type != 'learning_video' && !this.info?.description) ||
          (this.type == 'store_story' && !this.info?.logo) ||
          ((this.type == 'learning_recommend' ||
            this.type == 'learning_operating' ||
            this.type == 'learning_video' ||
            this.type == 'store_story') &&
            !this.info?.category)
        ) {
          this.$message.error('还有必填没填');
          return;
        }
        const options = {
          images: this.info?.cover, // 封面图/视频
          title: this.info?.title, // 文章标题
          publish_time: this.info?.publishTime, // 发布时间

          classify: this.type, //向哪项文章添加
        };
        // 文章正文
        if (this.info?.mainText) {
          options.content = this.info?.mainText;
        }
        // 文章描述
        if (this.info?.description) {
          options.description = this.info?.description;
        }
        // logo
        if (this.info?.logo) {
          options.logo = this.info?.logo; // logo
        }
        // 品类
        if (this.info?.category) {
          options.type = this.info?.category;
        }
        // 转载自
        if (
          this.type == 'policy_dynamics' ||
          this.type == 'dynamic_domestic' ||
          this.type == 'dynamic_border' ||
          this.type == 'dynamic_company'
        ) {
          options.reproduction = this.info?.reprint;
        }
        // 显示状态
        if (this.showSwitch) {
          options.status = '1';
        } else {
          options.status = '0';
        }

        // 置顶状态
        if (this.type == 'store_story') {
          if (this.topSwitch) {
            options.top_status = '1';
          } else {
            options.top_status = '0';
          }
        }
        // 首页显示状态
        if (
          this.type == 'policy_dynamics' ||
          this.type == 'dynamic_domestic' ||
          this.type == 'dynamic_border' ||
          this.type == 'dynamic_company'
        ) {
          if (this.homeSwitch) {
            options.home_page_status = '1';
          } else {
            options.home_page_status = '0';
          }
        }

        let res = '';
        if (this.rowInfo?.id) {
          // 编辑
          options.id = this.rowInfo?.id;
          res = await editActicle(options);
        } else {
          // 新增
          res = await addActicle(options);
        }
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.$emit('handle-confirm', this.type, 'acticle');
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击“取消”
    handleCancel() {
      this.$emit('handle-cancel');
    },
  },
};
