
// 变量
import { setSolicitationViewsField } from '../js/tablefield';
// api
import { deleteOpinion } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      oldData: this.tableData,
      // 弹框
      hasDoubleCheckDialog: false, //控制二次确认弹框
      rowInfo: {}, //记录选中的表格数据
      content: '您确定要删除吗？',
      hasPreview: false, //控制预览弹框
      previewLink: '', //选中预览的图片
    };
  },
  props: {
    // 表格数据
    // 例子：pages\back-stage\industry-trends\index.vue
    tableData: {
      type: Array,
      default: () => [],
    },
    /**
     * 区分什么类型的文章列表
     * 新闻分类 policy_dynamics: 政策动态，dynamic_domestic：国内新闻 ，dynamic_border：跨境资讯，dynamic_company：公司新闻，learning_center：学习中心，learning_recommend：选品推荐，learning_operating：运营干货
     */
    type: {
      type: String,
      default: '',
    },
    // 分页数据
    pageInfo: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    tableData(n) {
      if (n) {
        this.oldData = JSON.parse(JSON.stringify(n));
      }
    },
  },
  computed: {
    // 当前表格的配置
    curTableFiled() {
      return setSolicitationViewsField;
    },
    // 当前的表格信息
    curTableData() {
      return this.tableData;
    },
    // 当前分页数据
    curPageInfo() {
      return this.pageInfo;
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 分页切换
    goPages(val) {
      this.$emit('go-pages', val, this.type);
    },
    // 点击图片预览
    handlePreview(link) {
      this.previewLink = link;
      this.hasPreview = true;
    },
    // 关闭预览弹框
    handleClosePreview() {
      this.hasPreview = false;
      this.previewLink = '';
    },
    // 点击“编辑”
    handleEditor(row) {
      this.$emit('editor-acticle', row, this.type);
    },
    // 点击“删除”
    handleDelete(row) {
      this.rowInfo = row;
      this.hasDoubleCheckDialog = true;
    },
    // 二次确认弹框取消按钮
    handleCloseDia() {
      this.rowInfo = {};
      this.hasDoubleCheckDialog = false;
      this.content = '您确定要删除吗？';
    },
    // 二次确认弹框确认按钮
    async handleConfigDia() {
      const options = {
        id: this.rowInfo.id,
      };
      try {
        let res = await deleteOpinion(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.handleCloseDia();
          this.$emit('delete-data', this.type);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {},
};
