
export default {
  data() {
    return {
      type: 'images_pc', //images_pc-pc图片 images_mobile-手机图片
    };
  },
  props: {
    bannerInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    // 当前banner数据
    curInfo() {
      return this.bannerInfo || null;
    },
  },
  methods: {
    /**
     * 页面触发
     */
    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        this.type = 'images_mobile';
      } else {
        this.type = 'images_pc';
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener(
        'resize',
        this._throttle(this.checkScreenWidth, 200)
      );
    });
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.checkScreenWidth);
    }
  },
};
