// 设置核心优势表格字段
export const setCoreStrengths = [
  {
    text: '展示顺序',
    filed: 'sort',
    width: '150px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '优势文本',
    filed: 'words',
    width: '160px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '优势数据',
    filed: 'number',
    width: '160px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
];
