
export default {
  data() {
    return {
      activeName: '#' + this.mainList?.[0]?.anchorpoint || '',
    };
  },
  props: {
    mainList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    $route(n) {
      if (n?.hash) {
        this.activeName = n.hash
      }
    }
  },
};
