
import SearchBox from './components/SearchBox';
import SideNavigation from './components/SideNavigation';
import SideNavChose from './components/SideNavChose.vue';
import RuleDetail from './components/RuleDetail';
import { getRulesList, searchRules } from '@/api/client-api/front_desk.js';
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  name: 'PlatRule',
  components: {
    SearchBox,
    SideNavigation,
    SideNavChose,
    RuleDetail,
  },
  data() {
    return {
      ruleID: -1, // 用户规则文章ID
      ruleListSide: [], // 规则导航侧边栏
      ruleChoseList: [], // 选中展示的规则列表
      currently: '', // 侧边导航栏标识
      boxOrDetail: 'detail', // 右侧展示盒子还是详情
      searchKeyword: '', // 搜索关键词
      ruleDetail: {}, //规则详情
    };
  },
  // 需要seo的
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    try {
      const [resTdk] = await Promise.all([
        // 获取TDK
        app.$getSeoInfo({ id: '36' }),
      ]);
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return {
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/business-support/plat-rule/PlatRule.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        Title,
        Keywords,
        Description,
      };
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.ruleID = Number(this.$route.params.id);
      this.currently = 'child-' + this.ruleID.toString();
    }
    this.scrollToTop(); // 每次打开页面都是顶部
    this.getRulesSideList(); // 获取用户规则侧边栏列表
  },
  methods: {
    // 获取导航侧边栏
    getRulesSideList() {
      getRulesList().then((res) => {
        if (res?.code == 1) {
          const modifiedArray = res.data.map((item) => {
            const modifiedChildren = item.children.map((child) => {
              return {
                ...child,
                allid: `child-${child.id}`,
                otherMsg: [`发布于${child.plan_publish_time}`],
              };
            });
            return {
              ...item,
              children: modifiedChildren,
              allid: `parent-${item.id}`,
            };
          });
          this.ruleListSide = modifiedArray;

          if (this.ruleID == -1) {
            this.ruleID = modifiedArray[0].children[0].id;
            this.currently = modifiedArray[0].children[0].allid;
            this.$router.push('/platrule/' + this.ruleID);
          }
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 点击侧边栏一级分类
    clickFirstLevel(id) {
      this.currently = '';
      this.ruleID = -1;
      this.searchKeyword = '';
      this.boxOrDetail = 'box';
      document.documentElement.scrollTop = this.clickToTopRange;
      let options = {
        cateId: id,
      };
      getRulesList(options).then((res) => {
        if (res.code == 1) {
          const modifiedArray = res.data[0].children.map((item) => {
            return {
              ...item,
              otherMsg: [`发布于${item.plan_publish_time}`],
            };
          });
          this.$nextTick(() => {
            this.ruleChoseList = modifiedArray;
          });
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 点击侧边栏二级分类
    clickSecondLevel(allid, cid) {
      this.ruleID = cid;
      this.currently = allid;
      this.$router.push('/platrule/' + this.ruleID);

      this.searchKeyword = '';
      this.boxOrDetail = 'detail';
      document.documentElement.scrollTop = this.clickToTopRange;
    },
    // 点击标题盒子
    choseSpecific(cid) {
      this.ruleID = cid;
      this.$router.push('/platrule/' + this.ruleID);
      this.searchKeyword = '';
      this.currently = 'child-' + cid.toString();
      this.boxOrDetail = 'detail';
      document.documentElement.scrollTop = this.clickToTopRange;
    },
    // 点击搜索
    searchWord(keyword) {
      this.searchKeyword = keyword;
      this.currently = '';
      this.boxOrDetail = 'box';
      let options = {
        title: keyword,
      };
      searchRules(options).then((res) => {
        if (res.code == 1) {
          let dataType = Object.prototype.toString.call(res.data).slice(8, -1);
          if (dataType == 'String') {
            this.ruleChoseList = [];
          }
          if (dataType == 'Array') {
            const modifiedArray = res.data.map((item) => {
              return {
                ...item,
                otherMsg: [`发布于${item.plan_publish_time}`],
              };
            });
            this.ruleChoseList = modifiedArray;
          }
        } else {
          this.ruleChoseList = [];
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
