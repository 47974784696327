import { render, staticRenderFns } from "./XmUploadFileName.vue?vue&type=template&id=38d3ccd1&scoped=true"
import script from "./XmUploadFileName.vue?vue&type=script&lang=js"
export * from "./XmUploadFileName.vue?vue&type=script&lang=js"
import style0 from "./XmUploadFileName.vue?vue&type=style&index=0&id=38d3ccd1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d3ccd1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {XmHoverPreviewImage: require('D:/hky-repo/code/nuxt-office/components/xm-hover-preview-image/XmHoverPreviewImage.vue').default,XmPreview: require('D:/hky-repo/code/nuxt-office/components/xm-preview/XmPreview.vue').default})
