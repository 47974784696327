// 设置首页Banner图表格配置
export const setBannerField = [
  {
    text: '文章标题',
    filed: 'title',
    width: '130px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '封面图',
    filed: 'images',
    width: '140px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '显示状态',
    filed: 'status',
    width: '77px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '首页显示状态',
    filed: 'home_page_status',
    width: '110px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '转载地址',
    filed: 'reproduction',
    width: '80px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '发布时间',
    filed: 'publish_time',
    width: '110px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '修改时间',
    filed: 'update_time',
    width: '110px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '121px',
    isSort: false,
    isEdit: true,
  },
];

// 设置学习中心--选品推荐表格配置
export const recommendField = [
  {
    text: '文章标题',
    filed: 'title',
    width: '240px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '封面图/视频',
    filed: 'images',
    width: '168px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '描述',
    filed: 'description',
    width: '160px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '品类',
    filed: 'type',
    width: '160px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '显示状态',
    filed: 'status',
    width: '96px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '发布时间',
    filed: 'publish_time',
    width: '170px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '修改时间',
    filed: 'update_time',
    width: '170px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '116px',
    isSort: false,
    isEdit: true,
  },
];

// 设置学习中心--运营干货文章表格配置
export const dryInfoField = [
  {
    text: '文章标题',
    filed: 'title',
    width: '182px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '封面图/视频',
    filed: 'images',
    width: '182px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '分类',
    filed: 'type',
    width: '182px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '显示状态',
    filed: 'status',
    width: '182px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '发布时间',
    filed: 'publish_time',
    width: '182px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '修改时间',
    filed: 'update_time',
    width: '182px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '182px',
    isSort: false,
    isEdit: true,
  },
];

// 设置学习中心--操作视频表格配置
export const actionVideoField = [
  {
    text: '视频标题',
    filed: 'title',
    width: '182px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '视频',
    filed: 'images',
    width: '182px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '分类',
    filed: 'type',
    width: '182px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '显示状态',
    filed: 'status',
    width: '182px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '发布时间',
    filed: 'publish_time',
    width: '182px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '修改时间',
    filed: 'update_time',
    width: '182px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '182px',
    isSort: false,
    isEdit: true,
  },
];

// 设置商家故事--商家故事表格配置
export const storeStroyField = [
  {
    text: '文章标题',
    filed: 'title',
    width: '278px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '封面图/视频',
    filed: 'images',
    width: '168px',
    isSort: false,
    isEdit: true,
  },
  {
    text: 'logo图',
    filed: 'logo',
    width: '104px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '分类',
    filed: 'type',
    width: '82px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '显示状态',
    filed: 'status',
    width: '96px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '置顶状态',
    filed: 'top_status',
    width: '96px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '发布时间',
    filed: 'publish_time',
    width: '170px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '修改时间',
    filed: 'update_time',
    width: '170px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '116px',
    isSort: false,
    isEdit: true,
  },
];

// 意见征集
export const setSolicitationViewsField = [];
