
import ActicleBox from './ActicleBox'; // 文章展示盒子
export default {
  name: 'ActicleList',
  components: { ActicleBox },
  props: {
    // 文章列表
    acticleList: {
      type: Array,
      default: () => [],
    },
    // 跳转路由名字,前面带'/'
    routeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {},
};
