
export default {
  data() {
    return {
      imgType: 'card', //card-卡片类型
      arrowType: 'always', //always-始终出现 never-不出现
      indicatorPosition: 'none', //none-不出现
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    //当前所用的列表
    curlist() {
      return this.list || [];
    },
  },
  watch: {
    imgType() {
      this.$nextTick(() => {
        this.slideBanner();
      });
    },
  },
  methods: {
    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        this.imgType = '';
        this.arrowType = 'always';
        this.indicatorPosition = '';
      } else {
        this.imgType = 'card';
        this.arrowType = 'always';
        this.indicatorPosition = 'none';
      }
    },
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      let box = document.querySelector(
        '.xm-carousel-box-style .el-carousel__container'
      );
      let startPoint = 0;
      let stopPoint = 0;
      //重置坐标
      let resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      };
      //手指按下
      box.addEventListener('touchstart', function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener('touchmove', function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener('touchend', function (e) {
        console.log('1：' + startPoint);
        console.log('2：' + stopPoint);
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.xmcarousel.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.xmcarousel.prev();
          return;
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener(
        'resize',
        this._throttle(this.checkScreenWidth, 150)
      );
      this.slideBanner();
    });
  },
  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.checkScreenWidth);
    }
  },
};
