import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5c1c35de&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=5c1c35de&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c1c35de",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopNavigation: require('D:/hky-repo/code/nuxt-office/components/top-navigation/TopNavigation.vue').default,RotationChart: require('D:/hky-repo/code/nuxt-office/components/rotation-chart/RotationChart.vue').default,NavigationRight: require('D:/hky-repo/code/nuxt-office/components/navigation-right/NavigationRight.vue').default})
