import { Message } from 'element-ui';
import qs from 'qs';

export default ({ $axios, app }) => {
  $axios.onRequest((config) => {
    if (config.method == 'post') {
      if (!config.data) {
        config.data = {};
      }
      config.headers = Object.assign(config.headers || {}, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      if (config.dataType === 'form-data') {
        // 处理上传文件到后端的表单数据格式
        let formData = new FormData();
        for (let key of Object.keys(config.data)) {
          formData.append(key, config.data[key]);
        }
        config.data = formData;
      } else {
        config.data = qs.stringify(config.data);
      }
    } else {
      config.data = JSON.stringify(config.data);
      if (!config.params) {
        config.params = {};
      }
      config.headers = Object.assign(config.headers || {}, {
        'Content-Type': 'application/json; charset=utf-8',
      });
    }
    //判断当前请求是否设置了不显示Loading
    config.metadata = { startTime: new Date() };
    // console.log(`[API] startTime ${config.url} loaded in ${config.metadata.startTime.getTime()}`);
    return config;
  });
  /**
   * 请求拦截器，error
   */
  $axios.onRequestError((error) => {
    return Promise.reject(error);
  });

  /**
   * 响应拦截器
   */
  $axios.onResponse((response) => {
    if (response) {
      const { config } = response;
      const startTime = config?.metadata?.startTime;
      const endTime = new Date();
      const duration = endTime - startTime;
      // 打印接口响应时间
      console.log(`[API] ${config?.url} loaded in ${duration}ms`);
    }
    return Promise.resolve(response.data);
  });
  /**
   * 响应拦截器,error
   */
  $axios.onResponseError((error) => {
    let isTimeout = false;
    let errorMessage = '';
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          errorMessage = '错误请求';
          break;
        case 401:
          errorMessage = '未授权，请重新登录';
          break;
        case 403:
          errorMessage = '拒绝访问';
          break;
        case 404:
          errorMessage = '请求错误,未找到该资源';
          break;
        case 405:
          errorMessage = '请求方法未允许';
          break;
        case 408:
          errorMessage = '请求超时';
          break;
        case 500:
          errorMessage = '服务器端出错';
          break;
        case 501:
          errorMessage = '网络未实现';
          break;
        case 502:
          errorMessage = '网络错误';
          break;
        case 503:
          errorMessage = '服务不可用';
          break;
        case 504:
          errorMessage = '网络超时';
          break;
        case 505:
          errorMessage = 'http版本不支持该请求';
          break;
        default:
          errorMessage = `连接错误${error.response.status}`;
      }
    } else {
      errorMessage = '当前网络有波动，请刷新页面';
    }
    let muteError = error?.response?.config?.headers?.mute_msg_error == 1;
    if (errorMessage && !muteError) {
      // 统一弹出报错提示语
      Message.error(errorMessage);
    }
    return Promise.reject(error?.response ? error.response : error);
  });
};
