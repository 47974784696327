
// 组件
import ProcessBlocks from './ProcessBlocks';
import ImageShow from '@/pages/front-desk/components/ImageShow.vue';
// api
import { getConfig } from '@/api/client-api/front_desk.js';
export default {
  components: {
    ProcessBlocks,
    ImageShow,
  },
  data() {
    return {
      list: [
        { name: '免费入驻' },
        { name: '获得精准流量扶持' },
        { name: '获取全球分销渠道' },
        { name: '一对一注册指导' },
      ], //优惠内容
      data: 'test_upload_office-web_1705981522.mp4', //视频
      process: [
        {
          title: '准备相关入驻资料',
          text: '请您在正式入驻细目前，提前准备相关入驻资料，了解具体注册流程，助您顺利开店。',
        },
        {
          title: '了解细目规则',
          text: '所有商家在细目应当遵循公平、诚实原则，确保安全的购买及销售体验。',
        },
        { title: '一站式注册指导', text: '助您顺利入驻细目，助您卖货早一步。' },
        { title: '后续准备工作', text: '发布商品、安排物流等工作。' },
      ], //流程
      shopVideoInfo: {}, //视频设置数据
      // 预览
      previewLink: '',
      hasPreview: false,
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    /**
     * 页面触发
     */
    // 关闭预览
    handleClosePreview() {
      this.previewLink = '';
      this.hasPreview = false;
    },
    // 预览视频
    handlePreview(url) {
      this.previewLink = url;
      // this.hasPreview = true;
    },
    // 获取立即开店数据
    async getConfig() {
      try {
        let res = await getConfig();
        if (res?.code == 1) {
          this.shopVideoInfo = res?.data?.shop_video?.[0] || {};
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
