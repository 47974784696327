export const tableConfig = [
  {
    text: '页面',
    filed: 'page',
    width: '80px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '修改字段',
    filed: 'field',
    width: '110px',
    isSort: false,
    isEdit: false,
  },
  {
    text: '修改前数据',
    filed: 'old_data',
    width: '190px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '操作人',
    filed: 'operator',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '操作时间',
    filed: 'create_time',
    width: '150px',
    isSort: false,
    isEdit: false,
  },
];
