class Storage {
  constructor() {
    this.storageName = 'office-storage-name';
  }

  /**
   *  设置缓存
   *  @param {string} name 缓存名称
   *  @param {any} value 缓存的值
   *  @param {any} expires 缓存过期时间(秒)
   **/
  set(name, value, expires) {
    // 初始化
    let storages = {};
    // 获取缓存(判断是否过期)
    this.get(name);
    // 清除完过期之后，判断是否还有缓存
    if (localStorage.getItem(this.storageName)) {
      // 如果有缓存则，直接赋值
      storages = JSON.parse(localStorage.getItem(this.storageName));
    }

    // 如果有缓存则，直接赋值 或 初始化
    storages[name] = {
      value: storages?.[name] ? storages?.[name]?.value : value,
      expires: storages[name]
        ? storages[name].expires
        : expires === undefined
        ? +new Date() + 31536000000 //默认365天 这个值可以自己修改
        : expires * 1000 + +new Date(),
    };

    localStorage.setItem(this.storageName, JSON.stringify(storages));
  }

  /**
   *  获取缓存
   *  @param {string} name 缓存名称
   **/
  get(name) {
    const storages = JSON.parse(localStorage.getItem(this.storageName));
    try {
      if (!storages?.[name]) {
        // 不存在
        return null;
      }
      if (+new Date() > storages[name].expires) {
        // 存在但过期
        this.remove(name);
        return null;
      }
      return storages[name].value;
    } catch (error) {
      console.log(
        '[ControlStorage] the error message: get field failed\n',
        error
      );
    }
  }

  /**
   *  移除对应缓存
   *  @param {string} name 缓存名称
   **/
  remove(name) {
    const storages = JSON.parse(localStorage.getItem(this.storageName));
    try {
      delete storages[name];
      if (JSON.stringify(storages) === '{}') {
        // 缓存字段为空对象时，删除该字段
        this.clear();
        return;
      }
    } catch (error) {
      console.log(
        '[ControlStorage] the error message: remove field failed\n',
        error
      );
    }
  }
  /**
   *  清除所有带过期时间的缓存
   **/
  clear() {
    localStorage.removeItem(this.storageName);
  }
}

export default new Storage();
