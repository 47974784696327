
// 变量
import { tableConfig } from './js/tableConfig';
export default {
  data() {
    return {
      // 弹框
      previewUrl: '', //需要预览的
      showPreviewimg: false, //控制预览弹框
    };
  },
  props: {
    // 当前选中的标签
    curMain: {
      type: Object,
      default: () => {},
    },
    // 当前表格数据
    cetTableList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    curMain: {
      handler(n) {},
      deep: true,
    },
  },
  computed: {
    // 对应选中标签所用的列表
    curTableField() {
      if (this.curMain?.tableType) {
        return tableConfig[this.curMain?.tableType];
      }
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 关闭预览弹框
    previewCloseFunc() {
      this.showPreviewimg = false;
      this.previewUrl = '';
    },
    // 点击预览
    handlePreview(url) {
      this.previewUrl = url;
      this.showPreviewimg = true;
    },
  },
};
