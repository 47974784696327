export const seo = [
  {
    anchorpoint: 'tdktable',
    title: '页面TDK设置',
  },
  {
    anchorpoint: 'operationlog',
    title: '操作日志',
  },
];
