// 根据tableType 来区分用哪个表格配置
// 需要扩展可以直接加
// 1 - 首页banner
export const tableConfig = {
  1: [
    {
      text: '修改方式',
      filed: 'modifying_method',
      width: '80px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '展示顺序',
      filed: 'old_sort',
      width: '80px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '修改字段',
      filed: 'modifying_fields',
      width: '110px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '修改前数据',
      filed: 'modifying_before',
      width: '190px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作人',
      filed: 'operator',
      width: '150px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作时间',
      filed: 'create_time',
      width: '150px',
      isSort: false,
      isEdit: false,
    },
  ],
  2: [
    {
      text: '展示顺序',
      filed: 'old_sort',
      width: '80px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '修改字段',
      filed: 'modifying_fields',
      width: '110px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '修改前数据',
      filed: 'modifying_before',
      width: '190px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作人',
      filed: 'operator',
      width: '150px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作时间',
      filed: 'create_time',
      width: '150px',
      isSort: false,
      isEdit: false,
    },
  ],
  3: [
    {
      text: '修改方式',
      filed: 'modifying_method',
      width: '80px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '操作人',
      filed: 'operator',
      width: '150px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作时间',
      filed: 'create_time',
      width: '150px',
      isSort: false,
      isEdit: false,
    },
  ],
  4: [
    {
      text: '修改字段',
      filed: 'modifying_fields',
      width: '110px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '修改前数据',
      filed: 'modifying_before',
      width: '190px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作人',
      filed: 'operator',
      width: '150px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作时间',
      filed: 'create_time',
      width: '150px',
      isSort: false,
      isEdit: false,
    },
  ],
  5: [
    {
      text: '修改方式',
      filed: 'modifying_method',
      width: '80px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '修改前数据',
      filed: 'modifying_before',
      width: '190px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作人',
      filed: 'operator',
      width: '150px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作时间',
      filed: 'create_time',
      width: '150px',
      isSort: false,
      isEdit: false,
    },
  ],
  6: [
    {
      text: '修改方式',
      filed: 'modifying_method',
      width: '80px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '修改字段',
      filed: 'modifying_fields',
      width: '110px',
      isSort: false,
      isEdit: false,
    },
    {
      text: '修改前数据',
      filed: 'modifying_before',
      width: '190px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作人',
      filed: 'operator',
      width: '150px',
      isSort: false,
      isEdit: true,
    },
    {
      text: '操作时间',
      filed: 'create_time',
      width: '150px',
      isSort: false,
      isEdit: false,
    },
  ],
};
