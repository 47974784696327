
// api
import { editorAdvantage } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      info: JSON.parse(JSON.stringify(this.dataInfo || {})) || {},
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 点击“确定”
    async handleConfirm() {
      if (!this.info?.words) {
        this.$message.error('有必填项没填');
        return;
      }
      if (!this.info?.number) {
        this.$message.error('有必填项没填');
        return;
      }
      const options = {
        id: this.info.id || '', //修改的ID
        words: this.info.words || '', //修改的优势文本
        number: this.info.number || '', //修改的优势数据
      };
      let res = await editorAdvantage(options);
      if (res.code == 1) {
        this.$message.success(res.msg);
        this.$emit('handle-confirm');
      }
    },
    // 点击“取消”
    handleCancel() {
      this.$emit('handle-cancel');
    },
  },
};
