
import { logoff } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {};
  },
  methods: {
    // 退出登录
    async logOff() {
      let res = await logoff();
      if (res?.code == 1) {
        this.$message.success(res?.msg);
        this.$router.push('/login')
      }
    },
    // 修改密码
    goUrl() {
      this.$router.push('/modification')
    }
  },
};
