
export default {
  props: {
    // 总数量
    total: {
      type: [Number, String],
      default: 0,
    },
    // 条数
    pageSize: {
      type: [Number, String],
      default: 10,
    },
    // 页码
    pages: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {
      curPage: this.pages, //当前页码
    };
  },
  watch: {
    pages(newVal) {
      this.curPage = newVal;
    },
  },
  computed: {
    // 总页数
    totalPageNum() {
      if (!this.total) {
        return 0;
      } else {
        let num = this.total / this.pageSize;

        if (this.pageSize != 0 && this.total % this.pageSize == 0) {
          return parseInt(num.toString());
        }
        if (this.pageSize != 0 && this.total % this.pageSize != 0) {
          return parseInt(num.toString()) + 1;
        }
      }
    },
  },
  methods: {
    /**
     * 页面触发
     */
    handleGoPage(val) {
      // 点击上一页的情况
      if (val == -1) {
        if (this.curPage == 1) {
          return;
        }
      }
      // 点击下一页的情况
      if (val == 1) {
        if (this.curPage == this.totalPageNum) {
          return;
        }
      }
      this.curPage += val;
      console.log(this.curPage, 'this.curPage');
      this.$emit('go-page', this.curPage);
    },
  },
};
