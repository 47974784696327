export const AnchorPointNav = () => import('../..\\components\\anchor-point-nav\\AnchorPointNav.vue' /* webpackChunkName: "components/anchor-point-nav" */).then(c => wrapFunctional(c.default || c))
export const ContentBox = () => import('../..\\components\\content-box\\ContentBox.vue' /* webpackChunkName: "components/content-box" */).then(c => wrapFunctional(c.default || c))
export const DoubleCheckDialog = () => import('../..\\components\\double-check-dialog\\DoubleCheckDialog.vue' /* webpackChunkName: "components/double-check-dialog" */).then(c => wrapFunctional(c.default || c))
export const NavigationRight = () => import('../..\\components\\navigation-right\\NavigationRight.vue' /* webpackChunkName: "components/navigation-right" */).then(c => wrapFunctional(c.default || c))
export const RotationChart = () => import('../..\\components\\rotation-chart\\RotationChart.vue' /* webpackChunkName: "components/rotation-chart" */).then(c => wrapFunctional(c.default || c))
export const TableXmTable = () => import('../..\\components\\table\\XmTable.vue' /* webpackChunkName: "components/table-xm-table" */).then(c => wrapFunctional(c.default || c))
export const TopNavigation = () => import('../..\\components\\top-navigation\\TopNavigation.vue' /* webpackChunkName: "components/top-navigation" */).then(c => wrapFunctional(c.default || c))
export const XimuDialog = () => import('../..\\components\\ximu-dialog\\XimuDialog.vue' /* webpackChunkName: "components/ximu-dialog" */).then(c => wrapFunctional(c.default || c))
export const XmBreadcrumb = () => import('../..\\components\\xm-breadcrumb\\index.vue' /* webpackChunkName: "components/xm-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const XmDatePicker = () => import('../..\\components\\xm-date-picker\\index.vue' /* webpackChunkName: "components/xm-date-picker" */).then(c => wrapFunctional(c.default || c))
export const XmHoverPreviewImage = () => import('../..\\components\\xm-hover-preview-image\\XmHoverPreviewImage.vue' /* webpackChunkName: "components/xm-hover-preview-image" */).then(c => wrapFunctional(c.default || c))
export const XmPageTools = () => import('../..\\components\\xm-page-tools\\XmPageTools.vue' /* webpackChunkName: "components/xm-page-tools" */).then(c => wrapFunctional(c.default || c))
export const XmPaged = () => import('../..\\components\\xm-paged\\XmPaged.vue' /* webpackChunkName: "components/xm-paged" */).then(c => wrapFunctional(c.default || c))
export const XmPhonesPage = () => import('../..\\components\\xm-phones-page\\XmPhonesPage.vue' /* webpackChunkName: "components/xm-phones-page" */).then(c => wrapFunctional(c.default || c))
export const XmPreview = () => import('../..\\components\\xm-preview\\XmPreview.vue' /* webpackChunkName: "components/xm-preview" */).then(c => wrapFunctional(c.default || c))
export const XmUploadFileName = () => import('../..\\components\\xm-upload-file-name\\XmUploadFileName.vue' /* webpackChunkName: "components/xm-upload-file-name" */).then(c => wrapFunctional(c.default || c))
export const XmVideo = () => import('../..\\components\\xm-video\\XmVideo.vue' /* webpackChunkName: "components/xm-video" */).then(c => wrapFunctional(c.default || c))
export const TopNavigationComponentsMobileNavigation = () => import('../..\\components\\top-navigation\\components\\MobileNavigation.vue' /* webpackChunkName: "components/top-navigation-components-mobile-navigation" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
