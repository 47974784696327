
export default {
  name: 'VideoShow',
  props: {
    // 图片/视频url
    url: {
      type: String,
      default: '',
    },
    // 需要title/alt的传title
    title: {
      type: String,
      default: '',
    },
    // 是否需要点击预览
    isPreview: {
      type: Boolean,
      default: true,
    },
    // 是否需要悬浮放大
    isScale: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPreviewimg: false, // 预览弹窗状态
      isvideo: false,
    };
  },
  watch: {
    url() {
      this.$nextTick(() => {
        this.initPlayer();
      });
    },
  },
  mounted() {},
  methods: {
    // 初始化播放器组件实例
    initPlayer() {
      this.player = videojs('my-xm-video', {
        controls: true,
        preload: 'auto',
        autoplay: false,
        // 取消用户点击播放的事件监听
        userActions: {
          hotkeys: false,
          doubleClick: false,
          click: false,
          mousemove: false,
          mouseleave: false,
          keydown: false,
          customControlSpacer: false,
        },
      });
    },
    // 预览图片回调
    showPreviewimgFunc() {
      if (!this.isPreview) return;
      this.showPreviewimg = true;
    },
    // 关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = false;
    },
  },
};
