export const learningCenter = [
  {
    anchorpoint: 'recommendBanner',
    hasbtn: true,
    title: '选品推荐banner设置',
    cate_menu: 'learning_recommend',
    table_type: 'banner',
    dialog_title: '选品推荐banner',
    apiType: '1',
    searchlisttype: '1',
    tableType: '1',
  },
  {
    anchorpoint: 'recommendCate',
    hasbtn: true,
    title: '选品推荐品类',
    cate_menu: 'learning_recommend',
    apiType: '8',
    searchlisttype: '3',
    tableType: '5',
    operateMove: true,
  },
  {
    anchorpoint: 'recommendActicle',
    hasbtn: true,
    title: '选品推荐文章',
    cate_menu: 'learning_recommend',
    table_type: 'acticle',
    dialog_title: '选品推荐文章',
    apiType: '5',
    searchlisttype: '5',
    tableType: '6',
  },
  {
    anchorpoint: 'dryInfoBanner',
    hasbtn: true,
    title: '运营干货banner设置',
    cate_menu: 'learning_operating',
    table_type: 'banner',
    dialog_title: '运营干货banner',
    apiType: '1',
    searchlisttype: '1',
    tableType: '1',
  },
  {
    anchorpoint: 'dryInfoCate',
    hasbtn: true,
    title: '运营干货品类',
    cate_menu: 'learning_operating',
    apiType: '8',
    searchlisttype: '3',
    tableType: '5',
    operateMove: true,
  },
  {
    anchorpoint: 'dryInfoActicle',
    hasbtn: true,
    title: '运营干货文章',
    cate_menu: 'learning_operating',
    table_type: 'acticle',
    dialog_title: '运营干货文章',
    apiType: '5',
    searchlisttype: '5',
    tableType: '6',
  },
  {
    anchorpoint: 'videoBanner',
    title: '操作视频banner设置',
    cate_menu: 'learning_video',
    table_type: 'banner',
    dialog_title: '操作视频banner',
    apiType: '1',
    searchlisttype: '4',
    tableType: '4',
  },
  {
    anchorpoint: 'videoList',
    hasbtn: true,
    title: '操作视频设置',
    cate_menu: 'learning_video',
    table_type: 'acticle',
    dialog_title: '操作视频',
    apiType: '5',
    searchlisttype: '5',
    tableType: '6',
  },
  {
    anchorpoint: 'operationlog',
    title: '操作日志',
  },
];
