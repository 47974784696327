
export default {
  props: {
    // 导航数据
    navList: {
      type: Array,
      default: null,
    },
    // 一级标签对应id
    navId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      selectedNav: null, //被选中的一级标签
      selectedSecondNav: null, //被选中的二级标签
      selectedUrl: '', //被选中的路径标签
      num: 0, //用于列表的更新节点
      isShowNav: false, //控制导航板块的显示隐藏
    };
  },
  watch: {},
  computed: {
    //获取当前的导航信息
    curNavList() {
      let list = JSON.parse(JSON.stringify(this.navList || null)) || null;
      list?.forEach((item) => {
        item.isShowLi = false;
      });
      return list || null;
    },
  },
  methods: {
    /**
     * 数据处理
     */
    initData() {
      this.selectedUrl = this.$route?.fullPath;
    },
    /**
     * 页面触发
     */
    // 有子级的情况下点击一级标签
    handleNavFirst(navItem, list) {
      this.num++;
      if (this.selectedNav?.id == navItem?.id) {
        navItem.isShowLi = !navItem.isShowLi;
        return;
      }
      list?.forEach((item) => {
        item.isShowLi = false;
      });
      this.selectedNav = navItem;
      navItem.isShowLi = !navItem?.isShowLi || false;
    },
    // 控制导航板块的显示隐藏
    handleOpenNav() {
      this.isShowNav = !this.isShowNav;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initData();
    });
  },
  beforeDestroy() {},
};
