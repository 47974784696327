
// 组件
import SingleBanner from '@/pages/front-desk/components/SingleBanner'; //单张banner组件
import AboutUs from './components/AboutUs'; //关于我们
import BigFamily from './components/BigFamily'; //细目大家庭
import ContactUs from './components/ContactUs'; //联系我们
import History from './components/History'; //历史进程
import Footer from '@/pages/front-desk/components/footer/Footer'; //底部
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  components: {
    SingleBanner,
    AboutUs,
    BigFamily,
    ContactUs,
    History,
    Footer,
  },
  data() {
    return {
      // 面包屑列表
      breadList: [{ name: '企业简介', link: '' }],
      // banner数据
      bannerInfo: {
        images_pc: 'test_upload_office-web_1706149526.png',
        images_mobile: 'test_upload_office-web_1719470890.png',
      },
    };
  },
  // 需要seo的
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    let familyImgList = [];
    let historyList = [];

    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    try {
      const [resFamily, resDevelopmentHistory, resTdk] = await Promise.all([
        // 大家庭
        app.$getFamily(),
        // 历史进程
        app.$getDevelopmentHistory(),
        // 获取TDK
        app.$getSeoInfo({ id: '38' }),
      ]);
      if (resFamily?.code == 1) {
        familyImgList = resFamily?.data || [];
      }
      if (resDevelopmentHistory?.code == 1) {
        historyList = resDevelopmentHistory?.data || [];
      }
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return {
        familyImgList,
        historyList,
        Title,
        Keywords,
        Description,
      };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/about-us/company-profile/index.vue',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return {
        familyImgList,
        historyList,
        Title,
        Keywords,
        Description,
      };
    }
  },
};
