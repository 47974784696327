/**
 * 这是一个全局注册的组件的js呀._.,跟原零售商项目差不多用法呀
 * 公共的才放这里！！！！！！！！！！
 * 公共的才放这里！！！！！！！！！！
 * 公共的才放这里！！！！！！！！！！
 */

import Vue from 'vue';
import TopNavigation from '@/components/top-navigation/TopNavigation.vue'; //顶部导航（适配）
import RotationChart from '@/components/rotation-chart/RotationChart.vue'; //轮播图(适配)
import XmVideo from '@/components/xm-video/XmVideo.vue'; //视频
import ContentBox from '@/components/content-box/ContentBox.vue'; //内容盒子
import XmTable from '@/components/table/XmTable.vue'; //表格组件
import DoubleCheckDialog from '@/components/double-check-dialog/DoubleCheckDialog.vue'; //二次确认弹框
import XmPreview from '@/components/xm-preview/XmPreview.vue'; //预览（视频/图片）
import XmUploadFileName from '@/components/xm-upload-file-name/XmUploadFileName.vue'; // 图片上传
import AnchorPointNav from '@/components/anchor-point-nav/AnchorPointNav.vue'; //锚点导航
import XmDialog from '@/components/ximu-dialog/XimuDialog.vue'; //弹框
import XmHoverPreviewImage from '@/components/xm-hover-preview-image/XmHoverPreviewImage.vue'; //带用户上传文件名的上传组件
import XmPaged from '@/components/xm-paged/XmPaged.vue'; //分页
import XmDatePicker from '@/components/xm-date-picker/index.vue'; //时间范围搜索
import XmBreadcrumb from '@/components/xm-breadcrumb/index.vue'; // 面包屑导航
import XmPageTools from '@/components/xm-page-tools/XmPageTools.vue' // 分页器
import XmPhonesPage from "@/components/xm-phones-page/XmPhonesPage.vue";//分页器（手机）
import NavigationRight from '@/components/navigation-right/NavigationRight.vue'; // 右侧导航

const components = {
  TopNavigation,
  RotationChart,
  XmVideo,
  ContentBox,
  XmTable,
  DoubleCheckDialog,
  XmPreview,
  XmUploadFileName,
  AnchorPointNav,
  XmDialog,
  XmHoverPreviewImage,
  XmPaged,
  XmDatePicker,
  XmBreadcrumb,
  XmPageTools,
  NavigationRight,
  XmPhonesPage
};

Object.keys(components).forEach((component_name) => {
  Vue.component(component_name, components[component_name]);
});
