
// 变量
import { industryBackstage } from './js/industrybackstage';
// 组件
import ImgBanner from '@/pages/back-stage/components/ImgBanner.vue'; //banner图设置
import NewsDetails from '@/pages/back-stage/components/news-details/NewsDetails.vue'; //文章设置表格公共组件
import AddActicle from '@/pages/back-stage/components/AddActicle.vue'; // 文章新增/编辑弹窗
import OperationLog from '@/pages/back-stage/components/operation-Log/OperationLog.vue'; //操作日志
// api
import { getNews } from '@/api/client-api/backstageapi.js';
export default {
  components: { ImgBanner, NewsDetails, AddActicle, OperationLog },
  data() {
    return {
      mainList: industryBackstage, //盒子列表
      // 表格数据
      // 政策动态文章设置
      policy_dynamics: {
        page: 1,
        limit: 10,
        count: 0,
      }, //分页
      policy_dynamicsTableData: [], // 表格数据
      // 国内新闻文章设置
      dynamic_domestic: {
        page: 1,
        limit: 10,
        count: 0,
      }, //分页
      dynamic_domesticTableData: [], //表格数据
      // 跨境资讯文章设置
      dynamic_border: {
        page: 1,
        limit: 10,
        count: 0,
      }, //分页
      dynamic_borderTableData: [], //表格数据
      // 公司新闻文章设置
      dynamic_company: {
        page: 1,
        limit: 10,
        count: 0,
      }, //分页
      dynamic_companyTableData: [], //表格数据

      rowInfo: {}, // 需要编辑的当前行数据
      hasAddActicle: false, // 弹窗状态
      dialogTitle: '', // 弹窗标题
      actionClassify: '', // 后端需要的值
    };
  },
  mounted() {
    this.getDataList('policy_dynamics');
    this.getDataList('dynamic_domestic');
    this.getDataList('dynamic_border');
    this.getDataList('dynamic_company');
  },
  methods: {
    /**
     * 页面触发
     */
    // 分页切换
    goPages(page, type) {
      this[type].page = page;
      this.getDataList(type);
    },
    /**
     * 获取表格数据
     * @param {String} type
     * 新闻分类 policy_dynamics: 政策动态， dynamic_domestic：国内新闻 ，dynamic_border：跨境资讯，dynamic_company：公司新闻，learning_center：学习中心，learning_recommend：选品推荐，learning_operating：运营干货
     */
    async getDataList(type) {
      const options = {
        page: this[type]?.page,
        limit: this[type]?.limit,
        cat_type: type,
      };
      let res = await getNews(options);
      if (res?.code == 1) {
        this[type + 'TableData'] = res?.data?.data || [];
        this[type].count = res?.data?.count || 0;

        this[type + 'TableData'].forEach((item) => {
          // 显示状态
          item.switch = false;
          if (item.status == 1) {
            item.switch = true;
          }
          // 首页显示状态
          item.homeSwitch = false;
          if (item.home_page_status == 1) {
            item.homeSwitch = true;
          }
        });
      }
    },
    // 成功删除列表某个数据
    handleDeleteTable(type) {
      // 如果不是在第一页且表格数据只有一条被删除的情况
      if (this[type + 'TableData']?.length == 1 && this[type]?.page > 1) {
        this[type].page = this[type]?.page - 1;
      }
      this.getDataList(type);
    },
    // 点击新增按钮
    clickAddBtn(type) {
      this.actionClassify = type;
      if (type == 'policy_dynamics') {
        this.dialogTitle = '新增政策动态文章';
      }
      if (type == 'dynamic_domestic') {
        this.dialogTitle = '新增国内新闻文章';
      }
      if (type == 'dynamic_border') {
        this.dialogTitle = '新增跨境资讯文章';
      }
      if (type == 'dynamic_company') {
        this.dialogTitle = '新增公司新闻文章';
      }
      this.hasAddActicle = true; // 打开弹窗
    },
    // 文章操作弹窗点击确认
    handleConfirm(type) {
      this.handleCancel();
      this.getDataList(type); // 重新获取数据
    },
    // 文章操作弹窗点击取消
    handleCancel() {
      this.actionClassify = '';
      this.dialogTitle = '';
      this.rowInfo = {};
      this.hasAddActicle = false; // 关闭弹窗
    },
    /**
     * 文章表格点击编辑
     * @param {Object} row 选择编辑行的数据
     * @param {*} type 判断弹窗标题
     */
    handleEditorTable(row, type) {
      this.rowInfo = row;
      this.actionClassify = type;
      if (type == 'policy_dynamics') {
        this.dialogTitle = '编辑政策动态文章';
      }
      if (type == 'dynamic_domestic') {
        this.dialogTitle = '编辑国内新闻文章';
      }
      if (type == 'dynamic_border') {
        this.dialogTitle = '编辑跨境资讯文章';
      }
      if (type == 'dynamic_company') {
        this.dialogTitle = '编辑公司新闻文章';
      }
      this.hasAddActicle = true; // 打开弹窗
    },
  },
};
