import { render, staticRenderFns } from "./index.vue?vue&type=template&id=10c4274f&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=10c4274f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c4274f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopNavigation: require('D:/hky-repo/code/nuxt-office/components/top-navigation/TopNavigation.vue').default,XmBreadcrumb: require('D:/hky-repo/code/nuxt-office/components/xm-breadcrumb/index.vue').default,XmPageTools: require('D:/hky-repo/code/nuxt-office/components/xm-page-tools/XmPageTools.vue').default,XmPhonesPage: require('D:/hky-repo/code/nuxt-office/components/xm-phones-page/XmPhonesPage.vue').default,NavigationRight: require('D:/hky-repo/code/nuxt-office/components/navigation-right/NavigationRight.vue').default})
