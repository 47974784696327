
import { getListData, editSidebar } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      dataInfo: {}, //图片信息
      phoneInfo: {}, //手机信息
      // 电话号码
      oldTelephoneVal: '', //起到存储作用
      telephoneDisabled: true, //控制是否能修改
      // 弹框
      hasDoubleCheckDialog: false,
      content: '您确定要删除吗？',
    };
  },
  mounted() {
    this.getSetData();
  },
  methods: {
    /**
     * 页面触发
     */
    // 二次确认弹框确认按钮
    async handleConfigDia() {
      try {
        const options = {
          sidebar_qr: '',
          sidebar_phone: this.phoneInfo.value || '',
        };
        let res = await editSidebar(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.handleCloseDia();
          this.dataInfo.value = '';
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 二次确认弹框取消按钮
    handleCloseDia() {
      this.hasDoubleCheckDialog = false;
    },
    // 点击”删除“
    handleDelete() {
      this.hasDoubleCheckDialog = true;
    },
    // -------------------------------------------------------------
    // 上传成功回调
    async uploadFile(link,upType, type) {
      try {
        if (type != 'noTips') {
          const options = {
            sidebar_qr: link,
            sidebar_phone: this.phoneInfo.value || '',
          };
          let res = await editSidebar(options);
          if (res?.code == 1) {
            this.$message.success(res.msg);
          }
        }
        this.dataInfo.value = link;
      } catch (error) {
        console.error(error);
      }
    },
    // -------------------------------------------------------
    // 点击“完成”
    async handleDone() {
      try {
        const options = {
          sidebar_qr: this.dataInfo.value,
          sidebar_phone: this.phoneInfo.value || '',
        };
        let res = await editSidebar(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.telephoneDisabled = true;
          // 将新的值赋值给存储的旧值
          this.oldTelephoneVal = this.phoneInfo.value;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击“取消”
    handleCancel() {
      this.telephoneDisabled = true;
      // 回到初始值
      this.phoneInfo.value = this.oldTelephoneVal;
    },
    // 获取侧边栏设置
    async getSetData() {
      try {
        const options = {
          name: 'sidebar_qr,sidebar_phone',
        };
        let res = await getListData(options);
        if (res.code == 1) {
          res?.data?.forEach((item) => {
            // 电话信息
            if (item.name == 'sidebar_phone') {
              this.phoneInfo = JSON.parse(JSON.stringify(item || {}));
              this.oldTelephoneVal = item.value;
            }
            // 图片信息
            if (item.name == 'sidebar_qr') {
              this.dataInfo = JSON.parse(JSON.stringify(item || {}));
            }
          });
          console.log(this.dataInfo, 'this.dataInfo');
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
