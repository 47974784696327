
// api
import { editOpinionRecord } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      curRemark: this.rowInfo?.remark || '', //备注
    };
  },
  props: {
    // 获取到选中的表格数据
    rowInfo: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  methods: {
    /**
     * 页面触发
     */
    // 点击“发布”
    async handleConfirm() {
      if (!this.curRemark) {
        this.$message.warning('备注不能为空');
        return;
      }
      const options = {
        id: this.rowInfo.id,
        remark: this.curRemark,
      };
      try {
        let res = await editOpinionRecord(options);
        if (res?.code == 1) {
          this.$message.success(res.msg);
          this.$emit('handle-release');
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 点击取消
    handleCancel() {
      this.$emit('handle-cancel');
    },
  },
};
