
// 组件
import OperationSearch from './OperationSearch'; //搜索组件
import OperationTable from './OperationTable'; //表格数据
// api
import { getLogListSeo } from '@/api/client-api/backstageapi.js';

export default {
  components: {
    OperationSearch,
    OperationTable,
  },
  data() {
    return {
      // --------
      activeName:
        this.mainList[0]?.cate_menu + this.mainList[0]?.anchorpoint || '', //激活的标签名(初始拿第一个)
      apiActive: this.mainList[0]?.cate_menu || '', //激活哪一个API传参
      curMain: this.mainList?.[0] || {}, //选中的标签
      // 表格
      cetTableList: [], //表格数据
      page_num: 1, //页码
      page_size: 10, //每页数量
      total: 0,
      // 搜索
      searchData: {},
    };
  },
  props: {
    // 标签列表用于切换标签的列表
    mainList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // 当前所使用的列表
    curMainList() {
      return this.mainList;
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    /**
     * 页面触发
     */
    // 搜索
    changSearch(data) {
      this.searchData = data;
      this.rePage();
    },
    // 点击分页
    goPage(val) {
      this.page_num = val;
      this.getDataList();
    },
    // 重置页码
    rePage() {
      this.page_num = 1;
      this.getDataList();
    },
    // 点击标签事件
    handleClickLab(item) {
      if (item.cate_menu + item.anchorpoint == this.activeName) return;
      this.activeName = item.cate_menu + item.anchorpoint;
      this.apiActive = item.cate_menu;
      this.curMain = item;
      if (this.$refs?.operationsearch) {
        this.$refs?.operationsearch?.reSearch();
      }
    },
    // 获取列表数据
    async getDataList() {
      const options = {
        page_num: this.page_num,
        page_size: this.page_size,
        ...this.searchData,
      };
      try {
        let res = await getLogListSeo(options);
        if (res?.code == 1) {
          this.cetTableList = res?.data?.list || [];
          this.total = res?.data?.total || 0;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
