
// api
import { getConfig } from '@/api/client-api/front_desk.js';
export default {
  data() {
    return {
      // 数据
      telephone: '', //电话
      img: '',
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    /**
     * 页面触发
     */
    // 获取设置
    async getConfig() {
      try {
        let res = await getConfig();
        if (res?.code == 1) {
          this.telephone = res?.data?.sidebar_phone?.[0]?.value || '';
          this.img = res?.data?.sidebar_qr?.[0]?.value || '';
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
