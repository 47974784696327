
import GiftPackBox from './components/GiftPackBox.vue';
import ShopActicle from './components/ShopActicle'; // 文章内容
import Footer from '@/pages/front-desk/components/footer/Footer.vue'; // 页脚
export default {
  head() {
    return {
      title: this.Title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.Description,
        },
        {
          hid: 'Keywords',
          name: 'Keywords',
          content: this.Keywords,
        },
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' }],
    };
  },
  name: 'ShopEntry',
  components: { GiftPackBox, ShopActicle, Footer },
  data() {
    return {
      screenType: 'pc', //什么屏幕尺寸 pc phones-移动
      topBanner: {
        pc: 'test_upload_office-web_1706347311.png',
        phones: 'test_upload_office-web_1719221145.png',
      },
    };
  },
  /**
   *
   * @param {Object} app 相当于vue实例
   */
  async asyncData(app) {
    let url = ''; // 指导视频路径

    // TDK
    let Title = '';
    let Keywords = '';
    let Description = '';
    try {
      const [resChannel, resTdk] = await Promise.all([
        app.$getChannel({ user_type: '2' }),
        // 获取TDK
        app.$getSeoInfo({ id: '27' }),
      ]);
      if (resChannel?.code == 1) {
        url = resChannel?.msg?.video || {};
      }
      // 获取页面TDK
      if (resTdk?.code == 1) {
        let info = resTdk?.data?.[0] || {};
        Title = info?.title || '';
        Description = info?.description || '';
        Keywords = info?.keyword || '';
      }
      return { url, Title, Keywords, Description };
    } catch (error) {
      console.error(error);
      if (error?.status) {
        // 请求报错
        console.log(
          'front-desk/entry-channel/shop-entry',
          error?.status,
          error?.statusText
        );
      } else {
        // 代码报错
        console.log(error);
      }
      return { url, Title, Keywords, Description };
    }
  },
  computed: {},
  methods: {
    /**
     * 页面触发
     */
    checkScreenWidth() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1200) {
        this.screenType = 'phones';
      } else {
        this.screenType = 'pc';
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenWidth();
      window.addEventListener(
        'resize',
        this._throttle(this.checkScreenWidth, 100)
      );
    });
  },

  beforeDestroy() {
    if (process.client) {
      window.removeEventListener('resize', this.checkScreenWidth);
    }
  },
};
