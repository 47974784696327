
export default {
  data() {
    return {};
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
};
