export const industryBackstage = [
  {
    anchorpoint: 'setpoloicynewsbanner',
    hasbtn: true,
    title: '政策动态banner设置',
    cate_menu: 'policy_dynamics',
    apiType: '1',
    searchlisttype: '4',
    tableType: '4',
  },
  {
    anchorpoint: 'setpoloicynewsdetails',
    hasbtn: true,
    title: '政策动态文章设置',
    cate_menu: 'policy_dynamics',
    apiType: '5',
    searchlisttype: '5',
    tableType: '6',
  },
  {
    anchorpoint: 'setdomesticnewsbanner',
    hasbtn: true,
    title: '国内新闻banner设置',
    cate_menu: 'dynamic_domestic',
    apiType: '1',
    searchlisttype: '4',
    tableType: '4',
  },
  {
    anchorpoint: 'setdomesticnewsdetails',
    hasbtn: true,
    title: '国内新闻文章设置',
    cate_menu: 'dynamic_domestic',
    apiType: '5',
    searchlisttype: '5',
    tableType: '6',
  },
  {
    anchorpoint: 'setcrossboundarybanner',
    title: '跨境资讯banner设置',
    cate_menu: 'dynamic_border',
    apiType: '1',
    searchlisttype: '4',
    tableType: '4',
  },
  {
    anchorpoint: 'setcrossboundarydetails',
    hasbtn: true,
    title: '跨境资讯文章设置',
    cate_menu: 'dynamic_border',
    apiType: '5',
    searchlisttype: '5',
    tableType: '6',
  },
  {
    anchorpoint: 'setcompanynewsbanner',
    title: '公司新闻banner设置',
    cate_menu: 'dynamic_company',
    apiType: '1',
    searchlisttype: '4',
    tableType: '4',
  },
  {
    anchorpoint: 'setcompanynewsdetails',
    hasbtn: true,
    title: '公司新闻文章设置',
    cate_menu: 'dynamic_company',
    apiType: '5',
    searchlisttype: '5',
    tableType: '6',
  },
  {
    anchorpoint: 'operationlog',
    title: '操作日志',
  },
];
