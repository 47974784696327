
import { saveHotSearch, editHotSearch } from '@/api/client-api/backstageapi.js';
export default {
  data() {
    return {
      info: {
        sort: '', //序号
        words: '', //热搜词
      },
    };
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.setData();
  },
  methods: {
    /**
     * 页面触发
     */
    setData() {
      const options = {
        sort: '', //序号
        words: '', //热搜词
      };
      this.info = JSON.parse(JSON.stringify(this.dataInfo || options));
    },
    // 点击“确定”
    async handleConfirm() {
      if (!this.info?.sort || !this.info?.words) {
        this.$message.error('还有必填项未填写');
        return;
      }
      const options = {
        sort: this.info?.sort || '',
        words: this.info?.words || '',
      };
      let res;
      if (this.info?.id) {
        options.id = this.info?.id;
        res = await editHotSearch(options);
      } else {
        res = await saveHotSearch(options);
      }

      if (res?.code == 1) {
        this.$message.success(res.msg);
        this.$emit('handle-confirm');
      }
    },
    // 点击“取消”
    handleCancel() {
      this.$emit('handle-cancel');
    },
  },
};
