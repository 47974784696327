
export default {
  name: 'SideNavChose',
  props: {
    // 盒子列表
    boxList: {
      type: Array,
      default: () => [],
    },
    // 是否有顶部长度信息
    hasTopMsg: {
      type: Boolean,
      default: false,
    },
    // 顶部信息文字
    topTag: {
      type: String,
      default: '',
    },
    // 搜索的文字
    searchFont: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 选择具体标签跳转
    choseBox(id) {
      this.$emit('chose-specific', id);
    },
    // 搜索词变色
    brightenKeyword(item) {
      const REG = new RegExp(this.searchFont, 'g');
      return item.replace(
        REG,
        '<span style="color: #026bff">' + this.searchFont + '</span>'
      );
    },
  },
};
