import request from '@/utils/request.js';

// 获取品类数据
export function getClassifyType(data) {
  return request({
    url: '/xmadmin/news.news/getClassifyType',
    method: 'get',
    params: data,
  });
}

//  获取文章列表
export function getActicleList(data) {
  return request({
    url: '/xmadmin/news.news/index',
    method: 'get',
    params: data,
  });
}

// 添加文章
export function addActicle(data) {
  return request({
    url: '/xmadmin/news.news/add',
    method: 'post',
    data: data,
  });
}

// 编辑文章
export function editActicle(data) {
  return request({
    url: '/xmadmin/news.news/edit',
    method: 'post',
    data: data,
  });
}

// 获取文章详情
export function getActicleDetails(data) {
  return request({
    url: '/xmadmin/news.news/details',
    method: 'post',
    data: data,
  });
}

// 删除文章
export function delActicle(data) {
  return request({
    url: '/xmadmin/news.news/delete',
    method: 'post',
    data: data,
  });
}

// 特定规则的分类获取(规则)
export function getRulesCateList(data) {
  return request({
    url: '/xmadmin/news.cate/index',
    method: 'get',
    params: data,
  });
}

// 删除分类
export function delCate(data) {
  return request({
    url: '/xmadmin/news.cate/delete',
    method: 'post',
    data: data,
  });
}
