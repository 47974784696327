
// 组件
import OperationLog from '@/pages/back-stage/components/operation-Log/OperationLog.vue'; //操作日志
// 变量
import { logconfig } from './js/log_config';
export default {
  components: {
    OperationLog,
  },
  data() {
    return {
      mainList: logconfig,
      statusVal: '', //选中的文章状态
      statusOptions: [
        { label: '全部' },
        { label: '有效', value: '1' },
        { label: '无效', value: '0' },
      ], //文章状态列表
      // 弹框
      hasLog: false, //控制操作日志弹框
    };
  },
  methods: {
    /**
     * 页面触发
     */
    // 重置搜索
    reChange() {
      this.statusVal = '';
      this.changeSearch();
    },
    // 搜索事件
    changeSearch() {
      this.$emit('change-search', this.statusVal);
    },
    // 点击“新增文章”
    handleAddNews() {
      this.$emit('add-rules');
    },
    // 点击“编辑分类”
    handleEditorType() {
      this.$emit('editor-type');
    },
  },
};
