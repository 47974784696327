// 设置Banner图表格配置
export const setBannerField = [
  {
    text: '展示顺序',
    filed: 'sort',
    width: '150px',
    isSort: false,
    isEdit: false,
  },
  {
    text: 'PC端banner图',
    filed: 'images_pc',
    width: '160px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '移动端banner图',
    filed: 'images_mobile',
    width: '160px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '显示状态',
    filed: 'status',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
  {
    text: '操作',
    filed: 'operation',
    width: '150px',
    isSort: false,
    isEdit: true,
  },
];
