
export default {
  name: 'error',
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goback() {
      this.$router.go(-1);
    },
    gohome() {
      this.$router.push('/');
    }
  },
};
